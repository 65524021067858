import {
  ALL_DATABASES,
  ALL_SCHEMAS_ALL_DATABASES,
  ALL_TABLES_ALL_DATABASES,
  ALL_VIEWS_ALL_DATABASES,
  ANY_SCHEMA,
} from "./PrivilegesTable/constant";
import {
  PermissionError,
  PrivilegesState,
  PrivilegesStateWithErrors,
} from "./types";

export const ROLES_WIZARD_ADD_GROUP_EMPTY = "ROLES_WIZARD_ADD_GROUP_EMPTY";
export const ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS =
  "ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS";
export const ROLES_WIZARD_DELETE_GROUP = "ROLES_WIZARD_DELETE_GROUP";

export const ROLES_WIZARD_SET_RESOURCE_OBJECTS =
  "ROLES_WIZARD_SET_RESOURCE_OBJECTS";

export const ROLES_WIZARD_SET_ASSIGN_PRIVILEGES =
  "ROLES_WIZARD_SET_ASSIGN_PRIVILEGES";
export const ROLES_WIZARD_SET_REVOKE_PRIVILEGES =
  "ROLES_WIZARD_SET_REVOKE_PRIVILEGES";

export const ROLES_WIZARD_TOGGLE_EXPAND_ROWS =
  "ROLES_WIZARD_TOGGLE_EXPAND_ROWS";

export const ROLES_WIZARD_TOGGLE_EXPAND_GROUP =
  "ROLES_WIZARD_TOGGLE_EXPAND_GROUP";

export const ROLES_WIZARD_IGNORE_ERROR = "ROLES_WIZARD_IGNORE_ERROR";

const setElementAtIndex = (
  array: unknown[],
  element: unknown,
  index: number
) => {
  return [
    ...array.slice(0, index),
    element,
    ...array.slice(index + 1, array.length),
  ];
};

const hasDatabaseUsage = (
  state: PrivilegesStateWithErrors,
  catalogName: string
) => {
  const { database } = state;
  for (const databaseGroup of database) {
    const { objects, toAssign } = databaseGroup;
    if (objects.includes(catalogName) && toAssign.includes("USAGE")) {
      return true;
    }
  }
  return false;
};

const hasSchemaUsage = (
  state: PrivilegesStateWithErrors,
  schemaName: string,
  catalogName?: string
) => {
  for (const schemaGroup of state.schema) {
    const { objects, toAssign } = schemaGroup;
    const object = objects.find(object => {
      return (
        object.name === ANY_SCHEMA ||
        object.name === schemaName ||
        (catalogName &&
          object.catalogName === catalogName &&
          object.name === schemaName)
      );
    });
    if (toAssign.includes("USAGE") && object) {
      return true;
    }
  }
  return false;
};

const getPermissionErrors = (state: PrivilegesStateWithErrors) => {
  const { table, view, schema, permissionErrors } = state;

  const viewErrors = permissionErrors.view.filter(error => error.ignored);
  const tableErrors = permissionErrors.table.filter(error => error.ignored);
  const schemaErrors = permissionErrors.schema.filter(error => error.ignored);

  const exists = (
    errors: PermissionError[],
    {
      object,
      catalogName,
      resource,
    }: {
      object: string;
      catalogName?: string;
      resource: string;
    }
  ) => {
    const existingError = errors.find(error => {
      if (catalogName) {
        return (
          error.object === object &&
          error.catalogName === catalogName &&
          error.resource === resource
        );
      }
      return error.object === object && error.resource === resource;
    });

    return existingError;
  };

  const fillBulkSchemaErrors = (
    errors: PermissionError[],
    { name }: { name: string },
    actions: string[]
  ) => {
    const error = {
      resource: "schema",
      object: ALL_SCHEMAS_ALL_DATABASES,
      action: "USAGE",
    };
    !hasSchemaUsage(state, ALL_SCHEMAS_ALL_DATABASES) &&
      !exists(errors, error) &&
      errors.push({
        ...error,
        errorSource: {
          name,
          actions,
        },
      });
  };

  const fillBulkDatabaseErrors = (
    errors: PermissionError[],
    { name }: { name: string },
    actions: string[]
  ) => {
    const error = {
      resource: "database",
      object: ALL_DATABASES,
    };
    !hasDatabaseUsage(state, ALL_DATABASES) &&
      !exists(errors, error) &&
      errors.push({
        ...error,
        action: "USAGE",
        errorSource: {
          name,
          actions,
        },
      });
  };

  const fillSchemaErrors = (
    errors: PermissionError[],
    { name, catalogName }: { name: string; catalogName: string },
    actions: string[]
  ) => {
    const error = {
      resource: "schema",
      object: "public",
      catalogName,
      action: "USAGE",
    };
    !hasSchemaUsage(state, "public", catalogName) &&
      !exists(viewErrors, error) &&
      errors.push({
        ...error,
        errorSource: {
          name,
          actions,
        },
      });
  };

  const fillDatabaseErrors = (
    errors: PermissionError[],
    { name, catalogName }: { name: string; catalogName: string },
    actions: string[]
  ) => {
    const error = {
      resource: "database",
      object: catalogName,
      action: "USAGE",
    };
    !hasDatabaseUsage(state, catalogName) &&
      !exists(viewErrors, error) &&
      errors.push({
        ...error,
        errorSource: {
          name,
          actions,
        },
      });
  };

  for (const viewGroup of view) {
    const { objects, toAssign } = viewGroup;
    if (toAssign.length) {
      for (const view of objects) {
        if (view.name === ALL_VIEWS_ALL_DATABASES) {
          fillBulkDatabaseErrors(viewErrors, view, toAssign);
          fillBulkSchemaErrors(viewErrors, view, toAssign);
        } else {
          fillSchemaErrors(viewErrors, view, toAssign);
          fillDatabaseErrors(viewErrors, view, toAssign);
        }
      }
    }
  }

  for (const tableGroup of table) {
    const { objects, toAssign } = tableGroup;
    if (toAssign.length) {
      for (const table of objects) {
        if (table.name === ALL_TABLES_ALL_DATABASES) {
          fillBulkDatabaseErrors(tableErrors, table, toAssign);
          fillBulkSchemaErrors(tableErrors, table, toAssign);
        } else {
          fillSchemaErrors(tableErrors, table, toAssign);
          fillDatabaseErrors(tableErrors, table, toAssign);
        }
      }
    }
  }

  for (const schemaGroup of schema) {
    const { objects, toAssign } = schemaGroup;
    if (toAssign.length) {
      for (const schema of objects) {
        if (schema.name === ALL_SCHEMAS_ALL_DATABASES) {
          fillBulkDatabaseErrors(schemaErrors, schema, toAssign);
        } else {
          fillDatabaseErrors(schemaErrors, schema, toAssign);
        }
      }
    }
  }

  return {
    view: viewErrors,
    table: tableErrors,
    schema: schemaErrors,
  };
};

export const resources = [
  "engine",
  "account",
  "database",
  "table",
  "view",
  "schema",
];

export const privilegesReducer = (
  state: PrivilegesStateWithErrors,
  action: any
) => {
  switch (action.type) {
    case ROLES_WIZARD_ADD_GROUP_EMPTY: {
      const { resource, objects = [] } = action;
      const resourceGroups = state[resource as keyof PrivilegesState];
      const newGroup = {
        objects,
        toAssign: [],
        toRevoke: [],
      };
      return {
        ...state,
        [resource]: [...resourceGroups, newGroup],
      };
    }
    case ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS: {
      const { resource, allObjects } = action as {
        resource: string;
        allObjects: string[];
      };
      const resourceGroups = state[resource as keyof PrivilegesState];
      const affectedObjects = new Set();

      for (const group of resourceGroups) {
        const { objects } = group;
        objects.forEach(object => affectedObjects.add(object));
      }
      const remainingObjects = allObjects.filter(object => {
        return !affectedObjects.has(object);
      });
      return {
        ...state,
        [resource]: [
          ...resourceGroups,
          { objects: remainingObjects, toAssign: [], toRevoke: [] },
        ],
      };
    }
    case ROLES_WIZARD_DELETE_GROUP: {
      const { resource, index } = action;
      const resourceGroups = state[resource as keyof PrivilegesState];
      const newGroups = [
        ...resourceGroups.slice(0, index),
        ...resourceGroups.slice(index + 1, resourceGroups.length),
      ];
      const newState = {
        ...state,
        [resource]: newGroups,
      };
      const permissionErrors = getPermissionErrors(newState);
      return {
        ...newState,
        permissionErrors,
      };
    }
    case ROLES_WIZARD_SET_RESOURCE_OBJECTS: {
      const { resource, objects, index } = action;
      const groups = state[resource as keyof PrivilegesState];
      const resourceGroup = groups[index];

      const group = {
        ...resourceGroup,
        objects,
      };

      const newGroups = setElementAtIndex(groups, group, index);

      const newState = {
        ...state,
        [resource]: newGroups,
      };

      const permissionErrors = getPermissionErrors(newState);

      return {
        ...newState,
        permissionErrors,
      };
    }
    case ROLES_WIZARD_SET_ASSIGN_PRIVILEGES: {
      const { resource, privileges, index } = action;
      const groups = state[resource as keyof PrivilegesState];
      const resourceGroup = groups[index];

      const group = {
        ...resourceGroup,
        toAssign: privileges,
      };

      const newGroups = setElementAtIndex(groups, group, index);
      const newState = {
        ...state,
        [resource]: newGroups,
      };

      const permissionErrors = getPermissionErrors(newState);

      return {
        ...newState,
        permissionErrors,
      };
    }
    case ROLES_WIZARD_SET_REVOKE_PRIVILEGES: {
      const { resource, privileges, index } = action;
      const groups = state[resource as keyof PrivilegesState];
      const resourceGroup = groups[index];

      const group = {
        ...resourceGroup,
        toRevoke: privileges,
      };

      const newGroups = setElementAtIndex(groups, group, index);
      const newState = {
        ...state,
        [resource]: newGroups,
      };

      const permissionErrors = getPermissionErrors(newState);
      return {
        ...newState,
        permissionErrors,
      };
    }
    case ROLES_WIZARD_TOGGLE_EXPAND_GROUP: {
      const { index, resource } = action;
      const groups = state[resource as keyof PrivilegesState];
      const resourceGroup = groups[index];
      const group = {
        ...resourceGroup,
        expanded: !resourceGroup.expanded,
      };
      const newGroups = setElementAtIndex(groups, group, index);
      return {
        ...state,
        [resource]: newGroups,
      };
    }
    case ROLES_WIZARD_IGNORE_ERROR: {
      const { resource, error } = action;
      const { permissionErrors } = state;
      const currentResoruceErrors =
        permissionErrors[resource as keyof typeof permissionErrors];
      const resourceErrors = currentResoruceErrors.map(currentError => {
        if (
          currentError.resource === error.resource &&
          currentError.object === error.object &&
          currentError.catalogName === error.catalogName
        ) {
          return { ...currentError, ignored: true };
        }
        return currentError;
      });
      return {
        ...state,
        permissionErrors: {
          ...permissionErrors,
          [resource]: resourceErrors,
        },
      };
    }
    case ROLES_WIZARD_TOGGLE_EXPAND_ROWS: {
      const anyExpanded = resources.reduce((acc, resource) => {
        const resourceGroups = state[resource as keyof PrivilegesState];
        for (const group of resourceGroups) {
          if (group.expanded) {
            // eslint-disable-next-line no-param-reassign
            acc = true;
          }
        }
        return acc;
      }, false);

      const newState: any = {
        ...state,
      };
      for (const resource of resources) {
        const resourceGroups = state[resource as keyof PrivilegesState];
        const toggledResourceGroups = resourceGroups.map(group => {
          return {
            ...group,
            expanded: !anyExpanded,
          };
        });
        newState[resource as keyof PrivilegesState] = toggledResourceGroups;
      }

      return newState;
    }
    default: {
      return state;
    }
  }
};
