import { useTranslation } from "react-i18next";

import Button, { ButtonSize, ButtonTemplate } from "components/Button/Button";

import styles from "./styles.module.scss";

type Props = {
  addRelatedPermissions: () => void;
  ignorePermissionError: () => void;
  message: React.ReactNode;
  messagePermission: React.ReactNode;
  primaryActionText: string;
};

export const MissingPermissionError = (props: Props) => {
  const {
    addRelatedPermissions,
    ignorePermissionError,
    message,
    messagePermission,
    primaryActionText,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.error}>
      <div className={styles.message}>
        {messagePermission}
        <br />
        {message}
      </div>
      <div className={styles.footer}>
        <Button
          text={primaryActionText}
          template={ButtonTemplate.Primary}
          onClick={addRelatedPermissions}
          dataTestid="roles-wizard-add-permission"
          size={ButtonSize.Small}
        />
        <Button
          text={t("roles_wizard.missing_permission.ignore")}
          template={ButtonTemplate.Ghost}
          onClick={ignorePermissionError}
          dataTestid="roles-wizard-ignore-permission-error"
          size={ButtonSize.Small}
        />
      </div>
    </div>
  );
};
