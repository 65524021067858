export const ALL_DATABASES = "__ALL_DATABASES__";

export const ALL_ENGINES = "__ALL_ENGINES__";

export const ALL_TABLES = "__ALL_TABLES__";
export const ANY_TABLE = "__ANY_TABLE__";

export const ALL_VIEWS = "__ALL_VIEWS__";
export const ANY_VIEW = "__ANY_VIEW__";

export const ALL_SCHEMAS = "__ALL_SCHEMAS__";
export const ANY_SCHEMA = "__ANY_SCHEMA__";

export const ALL_TABLES_ALL_DATABASES = "__ALL_TABLES_ALL_DATABASES__";
export const ALL_VIEWS_ALL_DATABASES = "__ALL_VIEWS_ALL_DATABASES__";
export const ALL_SCHEMAS_ALL_DATABASES = "__ALL_SCHEMAS_ALL_DATABASES__";

export const CLEAR_ALL = "__CLEAR_ALL__";
export const SELECT_ALL = "__SELECT_ALL__";

export const bulkItemTitleMap = {
  [ALL_ENGINES]: "roles_wizard.privileges.all_engines",
  [ALL_TABLES]: "roles_wizard.privileges.all_tables",
  [ALL_DATABASES]: "roles_wizard.privileges.all_databases",
  [ALL_TABLES_ALL_DATABASES]: "roles_wizard.privileges.all_tables",
  [ALL_VIEWS]: "roles_wizard.privileges.all_views",
  [ALL_VIEWS_ALL_DATABASES]: "roles_wizard.privileges.all_views",
  [ALL_SCHEMAS]: "roles_wizard.privileges.all_schemas",
  [ALL_SCHEMAS_ALL_DATABASES]: "roles_wizard.privileges.all_schemas",
  [ANY_TABLE]: "roles_wizard.privileges.any_table",
};
