import {
  Modifier,
  Shortcut,
  ShortcutAction,
  ShortcutHandlerType,
} from "pages/DevelopWorkspace/services/shortcuts/types";

export const SHORTCUTS: {
  [key in ShortcutAction]: Shortcut;
} = {
  [ShortcutAction.EditorRunSelection]: {
    action: ShortcutAction.EditorRunSelection,
    title: "shortcuts.actions.run_selection",
    handlerType: ShortcutHandlerType.editor,
    mac: {
      hint: ["⌘", "↩"],
      command: null,
    },
    windows: {
      hint: ["Ctrl", "Enter"],
      command: null,
    },
  },
  [ShortcutAction.EditorRunAll]: {
    action: ShortcutAction.EditorRunAll,
    title: "shortcuts.actions.run_all",
    handlerType: ShortcutHandlerType.editor,
    mac: {
      hint: ["⌘", "⇧", "↩"],
      command: null,
    },
    windows: {
      hint: ["Ctrl", "Shift", "Enter"],
      command: null,
    },
  },
  [ShortcutAction.ScriptsClose]: {
    action: ShortcutAction.ScriptsClose,
    title: "shortcuts.editing.close_script",
    handlerType: ShortcutHandlerType.workspace,
    mac: {
      hint: ["⌘", "⌥", "X"],
      command: {
        codes: ["KeyX"],
        modifiers: [Modifier.metaKey, Modifier.altKey],
      },
    },
    windows: {
      hint: ["Ctrl", "Alt", "X"],
      command: {
        codes: ["KeyX"],
        modifiers: [Modifier.ctrlKey, Modifier.altKey],
      },
    },
  },
  [ShortcutAction.ScriptsCloseAll]: {
    action: ShortcutAction.ScriptsCloseAll,
    title: "shortcuts.sql_workspace.close_all_scripts",
    handlerType: ShortcutHandlerType.workspace,
    mac: {
      hint: ["⌘", "⌥", "G"],
      command: {
        codes: ["KeyG"],
        modifiers: [Modifier.metaKey, Modifier.altKey],
      },
    },
    windows: {
      hint: ["Ctrl", "Alt", "G"],
      command: {
        codes: ["KeyG"],
        modifiers: [Modifier.ctrlKey, Modifier.altKey],
      },
    },
  },
  [ShortcutAction.ScriptsCloseAllOther]: {
    action: ShortcutAction.ScriptsCloseAllOther,
    title: "shortcuts.sql_workspace.close_all_other_scripts",
    handlerType: ShortcutHandlerType.workspace,
    mac: {
      hint: ["⌘", "⌥", "O"],
      command: {
        codes: ["KeyO"],
        modifiers: [Modifier.metaKey, Modifier.altKey],
      },
    },
    windows: {
      hint: ["Ctrl", "Alt", "O"],
      command: {
        codes: ["KeyO"],
        modifiers: [Modifier.ctrlKey, Modifier.altKey],
      },
    },
  },
  [ShortcutAction.ScriptOpenNew]: {
    action: ShortcutAction.ScriptOpenNew,
    title: "shortcuts.sql_workspace.open_new_script",
    handlerType: ShortcutHandlerType.workspace,
    mac: {
      hint: ["⌘", "⌥", "N"],
      command: {
        codes: ["KeyN"],
        modifiers: [Modifier.metaKey, Modifier.altKey],
      },
    },
    windows: {
      hint: ["Ctrl", "Alt", "N"],
      command: {
        codes: ["KeyN"],
        modifiers: [Modifier.ctrlKey, Modifier.altKey],
      },
    },
  },
  [ShortcutAction.ShortcutsOverlayOpen]: {
    action: ShortcutAction.ShortcutsOverlayOpen,
    title: "shortcuts.sql_workspace.open_shortcuts_overlay",
    handlerType: ShortcutHandlerType.workspace,
    mac: {
      hint: ["⌃", "⇧", "?"],
      command: {
        codes: ["Slash"],
        modifiers: [Modifier.ctrlKey, Modifier.shiftKey],
      },
    },
    windows: {
      hint: ["Ctrl", "Shift", "?"],
      command: {
        codes: ["Slash"],
        modifiers: [Modifier.ctrlKey, Modifier.shiftKey],
      },
    },
  },
  [ShortcutAction.ScriptFormat]: {
    action: ShortcutAction.ScriptFormat,
    title: "shortcuts.editing.format",
    handlerType: ShortcutHandlerType.workspace,
    mac: {
      hint: ["⌘", "⌥", "F"],
      command: {
        codes: ["KeyF"],
        modifiers: [Modifier.metaKey, Modifier.altKey],
      },
    },
    windows: {
      hint: ["Ctrl", "Alt", "F"],
      command: {
        codes: ["KeyF"],
        modifiers: [Modifier.ctrlKey, Modifier.altKey],
      },
    },
  },
  [ShortcutAction.ScriptNext]: {
    action: ShortcutAction.ScriptNext,
    title: "shortcuts.sql_workspace.next_script",
    handlerType: ShortcutHandlerType.workspace,
    mac: {
      hint: ["⌘", "⌥", "]"],
      command: {
        codes: ["BracketRight"],
        modifiers: [Modifier.metaKey, Modifier.altKey],
      },
    },
    windows: {
      hint: ["Ctrl", "Alt", "]"],
      command: {
        codes: ["BracketRight"],
        modifiers: [Modifier.ctrlKey, Modifier.altKey],
      },
    },
  },
  [ShortcutAction.ScriptPrevious]: {
    action: ShortcutAction.ScriptPrevious,
    title: "shortcuts.sql_workspace.previous_script",
    handlerType: ShortcutHandlerType.workspace,
    mac: {
      hint: ["⌘", "⌥", "["],
      command: {
        codes: ["BracketLeft"],
        modifiers: [Modifier.metaKey, Modifier.altKey],
      },
    },
    windows: {
      hint: ["Ctrl", "Alt", "["],
      command: {
        codes: ["BracketLeft"],
        modifiers: [Modifier.ctrlKey, Modifier.altKey],
      },
    },
  },
  [ShortcutAction.CloseOverlay]: {
    action: ShortcutAction.CloseOverlay,
    title: "shortcuts.sql_workspace.close_overlay",
    handlerType: ShortcutHandlerType.none,
    mac: {
      hint: ["Esc"],
      command: null,
    },
    windows: {
      hint: ["Esc"],
      command: null,
    },
  },
  [ShortcutAction.ToggleResultsSection]: {
    action: ShortcutAction.ToggleResultsSection,
    title: "shortcuts.sql_workspace.toggle_results_section",
    handlerType: ShortcutHandlerType.workspace,
    mac: {
      hint: ["⌘", "⌥", "E"],
      command: {
        codes: ["KeyE"],
        modifiers: [Modifier.metaKey, Modifier.altKey],
      },
    },
    windows: {
      hint: ["Ctrl", "Alt", "E"],
      command: {
        codes: ["KeyE"],
        modifiers: [Modifier.ctrlKey, Modifier.altKey],
      },
    },
  },
  [ShortcutAction.ScriptToggleComment]: {
    action: ShortcutAction.ScriptToggleComment,
    title: "shortcuts.editing.toggle_comments",
    handlerType: ShortcutHandlerType.editor,
    mac: {
      hint: ["⌘", "/"],
      command: null,
    },
    windows: {
      hint: ["Ctrl", "/"],
      command: null,
    },
  },
};

export const SHORTCUT_GROUPS = {
  action: {
    groupTitle: "shortcuts.actions.title",
    shortcuts: [ShortcutAction.EditorRunSelection, ShortcutAction.EditorRunAll],
  },
  editing: {
    groupTitle: "shortcuts.editing.title",
    shortcuts: [
      ShortcutAction.ScriptToggleComment,
      ShortcutAction.ScriptFormat,
      ShortcutAction.ScriptsClose,
    ],
  },
  workspace: {
    groupTitle: "shortcuts.sql_workspace.title",
    shortcuts: [
      ShortcutAction.ScriptsCloseAllOther,
      ShortcutAction.ScriptsCloseAll,
      ShortcutAction.ScriptOpenNew,
      ShortcutAction.ScriptPrevious,
      ShortcutAction.ScriptNext,
      ShortcutAction.ToggleResultsSection,
      ShortcutAction.CloseOverlay,
      ShortcutAction.ShortcutsOverlayOpen,
    ],
  },
};
