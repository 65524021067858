import { useTranslation } from "react-i18next";

import { SqlRbacAction } from "services/rbac/action";
import { User } from "services/users/user.types";
import { getCreateStatement } from "services/users/utils";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";

import { useMenu } from "components/ActionMenu/useMenu";
import { useAccessManager } from "components/App/accessManager";
import DefinitionCodePreview from "components/DefinitionCodePreview/DefinitionCodePreview";

import { RecordLabel } from "../RecordLabel";
import { Menu } from "../RecordMenu/RecordMenu";
import { RecordNode } from "../RecordNode";
import { useDeleteUser } from "./useDeleteUser";
import { useEditUser } from "./useEditUser";

import styles from "./styles.module.scss";

type Props = {
  user: User;
  openDeleteUser: ReturnType<typeof useDeleteUser>["openDeleteUser"];
  openEditUser: ReturnType<typeof useEditUser>["openEditUser"];
};

export const UserRecord = (props: Props) => {
  const { user, openDeleteUser, openEditUser } = props;
  const { userName } = user;
  const { t } = useTranslation();
  const menu = useMenu();
  const { insertTextAtCursorPosition } = useActiveEditorView();
  const { isAllowedTo } = useAccessManager();

  const canEditUser = isAllowedTo(
    "account",
    "*",
    SqlRbacAction.USER_MODIFY_ANY
  );

  const deleteItems = canEditUser
    ? [
        [
          {
            key: "delete",
            text: t("workspace.user_menu.delete"),
            action: () => {
              openDeleteUser(userName);
            },
            testId: "delete-user-item",
          },
        ],
      ]
    : [];

  const editItems = canEditUser
    ? [
        {
          key: "edit",
          text: t("workspace.user_menu.edit"),
          action: () => {
            openEditUser(user);
          },
          testId: "edit-user-item",
        },
      ]
    : [];

  const items = [
    [
      ...editItems,
      {
        key: "view_definition",
        text: t("workspace.user_menu.view_definition"),
        renderContent: (props: { onContentClose: () => void }) => {
          const code = getCreateStatement(user, true);

          return (
            <DefinitionCodePreview
              code={code}
              onSecondaryButtonClick={() => {
                insertTextAtCursorPosition(code);
                menu.closeMenu();
                props.onContentClose();
              }}
            />
          );
        },
        testId: "view-user-definition-item",
      },
    ],
    ...deleteItems,
  ];
  return (
    <RecordNode className={styles.node}>
      <div className={styles.node__inner}>
        <RecordLabel
          className={styles.label}
          testId={`user-label-${userName}`}
        >
          {userName}
        </RecordLabel>
        <Menu
          testId="user-more-icon"
          items={items}
          menu={menu}
        />
      </div>
    </RecordNode>
  );
};
