import { createBrowserRouter } from "react-router-dom";

import AppInner from "./App/AppInner";
import ErrorBoundary from "./ErrorBoundary";

export const router = createBrowserRouter([
  {
    path: "*",
    element: (
      <ErrorBoundary>
        <AppInner />
      </ErrorBoundary>
    ),
  },
]);
