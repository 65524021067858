import { useTranslation } from "react-i18next";

import { getDatabaseDefinitions } from "services/databases/createWorkspaceDatabase";
import { SqlRbacAction } from "services/rbac/action";

import DatabaseIcon from "assets/icons/workspace/Database.svg?react";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import { useMenu } from "components/ActionMenu/useMenu";
import { useAccessManager } from "components/App/accessManager";
import DefinitionCodePreview from "components/DefinitionCodePreview/DefinitionCodePreview";
import { IngestionStep } from "components/IngestionWizard/types";
import { RecordLabelWithIcon } from "components/LeftSidebar/RecordLabelWithIcon";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import { TreeNodeType } from "components/Tree/types";

import {
  RecordMenuItemGroup,
  RecordMenuItemSubGroup,
} from "../../RecordMenu/types";
import { DatabaseMenuActions } from "../types";

import styles from "./styles.module.scss";

type Props = {
  node: TreeNodeType;
  actions: DatabaseMenuActions;
};

export const DatabaseLabel = (props: Props) => {
  const { node, actions } = props;
  const { deleteDatabase, openLoadData } = actions;
  const {
    state: { activeDocumentId },
    actions: { changeDocumentContext },
  } = useDocuments();
  const { label, value, payload } = node;
  const menu = useMenu();
  const { t } = useTranslation();
  const { isAllowedTo } = useAccessManager();
  const { insertTextAtCursorPosition } = useActiveEditorView();
  const { getAccount } = useCurrentAccount();
  const account = getAccount();

  const canDeleteDatabase = isAllowedTo(
    "database",
    value,
    SqlRbacAction.MODIFY,
    { owner: payload.catalogOwner }
  );

  const canCreateDatabase = isAllowedTo(
    "account",
    account?.accountName as string,
    SqlRbacAction.DATABASE_CREATE
  );

  const generateSecondaryItems = (): RecordMenuItemGroup => {
    const items: RecordMenuItemSubGroup = [];

    if (canCreateDatabase) {
      items.push({
        key: "load-wizard-with-db",
        text: t("workspace.database_menu.load_data"),
        action: () => {
          openLoadData({
            [IngestionStep.selectDestination]: {
              type: null,
              database: value,
            },
          });
        },
        testId: "load-wizard-with-db-item",
      });
    }

    if (canDeleteDatabase) {
      items.push({
        key: "delete",
        text: t("workspace.database_menu.delete"),
        action: () => {
          deleteDatabase(value);
        },
        testId: "delete-db-item",
      });
    }

    return items.length ? [items] : [];
  };

  const items = [
    [
      {
        key: "context",
        text: t("workspace.database_menu.context"),
        action: () => {
          if (activeDocumentId) {
            changeDocumentContext(activeDocumentId, {
              databaseName: value,
            });
          }
        },
        testId: "set-db-document-context-item",
      },
      {
        key: "insert",
        text: t("workspace.database_menu.insert"),
        shortcut: "Shift + click",
        action: () => {
          if (activeDocumentId) {
            insertTextAtCursorPosition(value);
          }
        },
        testId: "insert-db-into-editor-item",
      },
      {
        key: "view",
        text: "View database definition",
        renderContent: (props: { onContentClose: () => void }) => {
          const code = getDatabaseDefinitions({
            databaseName: value,
            description: payload.description,
          });
          return (
            <DefinitionCodePreview
              code={code}
              onSecondaryButtonClick={() => {
                if (activeDocumentId) {
                  insertTextAtCursorPosition(code);
                }
                menu.closeMenu();
                props.onContentClose();
              }}
            />
          );
        },
        testId: "view-db-definition-item",
      },
    ],
    ...generateSecondaryItems(),
  ];

  return (
    <>
      <RecordLabelWithIcon
        icon={<DatabaseIcon />}
        htmlTitle={label}
        label={<span className={styles.databaseLabel}>{label}</span>}
        testId={`db-label-${label}`}
      />
      <Menu
        testId="database-more-icon"
        items={items}
        menu={menu}
      />
    </>
  );
};
