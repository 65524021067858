import { useTranslation } from "react-i18next";

import { useSchemaNames } from "services/databases/useSchemaNames";

import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { WithSearch } from "components/OutlinedSelect/OutlinedSelect";

import { ROLES_WIZARD_SET_RESOURCE_OBJECTS } from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { SelectItems } from "./SelectItems";
import { ALL_SCHEMAS, ALL_SCHEMAS_ALL_DATABASES } from "./constant";

type Props = {
  catalogName: string;
  index: number;
  resource: string;
};

export const SchemasItems = (props: Props) => {
  const { index, catalogName, resource } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();
  const schemas = useSchemaNames({ database: catalogName });
  const { t } = useTranslation();

  const groupState = privilegesState.schema[index];
  const { objects } = groupState;

  const selectedSchemas = objects
    .filter(object => object.catalogName === catalogName)
    .map(object => object.name);

  const filterBulkResources = (item: { name: string; catalogName: string }) => {
    if (item.name === ALL_SCHEMAS_ALL_DATABASES) {
      return false;
    }
    if (item.catalogName !== catalogName) {
      return true;
    }
    if (item.catalogName === catalogName) {
      return item.name !== ALL_SCHEMAS;
    }
  };

  const handleResource = (schema: { schemaName: string }) => {
    const selectedObjects = selectedSchemas.includes(schema.schemaName)
      ? objects.filter(object => {
          if (object.catalogName !== catalogName) {
            return true;
          }
          return object.name !== schema.schemaName;
        })
      : [...objects, { name: schema.schemaName, catalogName }].filter(
          filterBulkResources
        );

    privilegesDispatch({
      type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
      resource,
      index,
      database: catalogName,
      objects: selectedObjects,
    });
  };

  const items = schemas.map(schema => {
    return (
      <ContextMenuItem
        key={schema.schemaName}
        value={schema.schemaName}
        text={schema.schemaName}
        direction="left"
        checked={selectedSchemas.includes(schema.schemaName)}
        onClick={() => {
          handleResource(schema);
        }}
      />
    );
  });

  return (
    <WithSearch
      items={items}
      searchOptions={{
        searchPlaceholder: t("roles_wizard.privileges.search_schema"),
      }}
    >
      <SelectItems />
    </WithSearch>
  );
};
