import _orderBy from "lodash/orderBy";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useDatabasesNames } from "services/databases/useDatabasesNames";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";
import { systemEngineEnvironment } from "services/environment/systemEngine";
import { ReactQueryKeysAccount } from "services/queryKeys";
import { createRole } from "services/rbac/createRole";
import { deleteRole } from "services/rbac/deleteRole";
import { getGrantStatements, getRevokeStatements } from "services/rbac/utils";
import { useUsers } from "services/users/useUsers";
import { User } from "services/users/user.types";
import { getGrantStatement } from "services/users/utils";

import { Privilege } from "pages/govern/Roles/PrivilegesTransaction";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";
import { Search } from "components/LeftSidebar/Search";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";
import { Step } from "components/Wizard/Step/Step";
import { queryClient } from "components/queryClient";

import { RolesDataType, RolesWizardStep } from "../types";
import { mapPrivilegeObjects } from "./mapPrivilegeObjects";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
  onPrevStep: () => void;
  onFinished: () => void;
  activeStepIndex: number;
  rolesData: RolesDataType;
};

const types = ["account", "database", "engine", "schema", "table", "view"];

export const AssignStep = (props: Props) => {
  const { onClose, onFinished, activeStepIndex, onPrevStep, rolesData } = props;
  const { t } = useTranslation();
  const databases = useDatabasesNames();
  const { data: engines } = useWorkspaceEngines({ includeSystemEngine: false });
  const { putStatusMessage } = useStatusMessageQueue();

  const currentAccount = useCurrentParamsAccount();
  const users = useUsers();

  const dependencies = {
    currentAccount,
    databases,
    engines,
  };

  const { name } = rolesData.name;
  const { privileges } = rolesData.privileges;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  const filteredUsers = _orderBy(
    users.filter(({ userName }) =>
      userName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    ),
    ({ userName }) => userName.toLocaleLowerCase()
  );

  const handleSelectUser = (user: User) => {
    const items = selectedUsers.includes(user.userName)
      ? selectedUsers.filter(u => u !== user.userName)
      : [...selectedUsers, user.userName];
    setSelectedUsers(items);
  };

  const body = (
    <div className={styles.wrapper}>
      <Search
        value={search}
        onChange={handleChangeSearch}
        placeholder={t("roles_wizard.assign.search_placeholder")}
        testId="roles-wizard-search-users"
        className={styles.search}
      />

      <ContextMenu>
        <ContextMenuItemsGroup
          isGroup
          maxHeight={300}
        >
          {filteredUsers.map(user => {
            return (
              <ContextMenuItem
                key={user.userName}
                value={user.userName}
                text={user.userName}
                direction="left"
                checked={selectedUsers.includes(user.userName)}
                onClick={() => {
                  handleSelectUser(user);
                }}
                disabled={isLoading}
              />
            );
          })}
        </ContextMenuItemsGroup>
      </ContextMenu>
    </div>
  );

  const grantRoleToUsers = async () => {
    const { name } = rolesData[RolesWizardStep.name];
    for (const user of selectedUsers) {
      const statement = getGrantStatement(user, name);
      // eslint-disable-next-line no-await-in-loop
      await systemEngineEnvironment.execute(statement);
    }
  };

  const getAndExecuteStatements = async (
    getStatements: (
      accountName: string,
      roleName: string,
      grant: Privilege
    ) => string[],
    privileges: {
      type: string;
      resource: string;
      catalogName: string | undefined;
      actions: string[];
    }[]
  ) => {
    for (const item of privileges) {
      const statements = getStatements(currentAccount.accountName, name, item);
      for (const statement of statements) {
        // eslint-disable-next-line no-await-in-loop
        await systemEngineEnvironment.execute(statement, {
          database: item.catalogName,
        });
      }
    }
  };

  const revokeGroupPrivileges = async (
    type: string,
    group: {
      toAssign: string[];
      toRevoke: string[];
      objects: string[] | { name: string; catalogName: string }[];
    }
  ) => {
    const { toRevoke, objects } = group;
    const revokePrivileges = await mapPrivilegeObjects(
      type,
      toRevoke,
      objects,
      dependencies
    );
    await getAndExecuteStatements(getRevokeStatements, revokePrivileges);
  };

  const grantGroupPrivileges = async (
    type: string,
    group: {
      toAssign: string[];
      toRevoke: string[];
      objects: string[] | { name: string; catalogName: string }[];
    }
  ) => {
    const { toAssign, objects } = group;
    const grantPrivileges = await mapPrivilegeObjects(
      type,
      toAssign,
      objects,
      dependencies
    );
    await getAndExecuteStatements(getGrantStatements, grantPrivileges);
  };

  const onFormSubmit = async () => {
    if (isLoading || !privileges) {
      return;
    }

    let roleCreated = false;

    try {
      setIsLoading(true);
      await createRole(name);
      roleCreated = true;

      for (const type of types) {
        const privilegesGroups = privileges[type as keyof typeof privileges];
        for (const group of privilegesGroups as []) {
          // eslint-disable-next-line no-await-in-loop
          await revokeGroupPrivileges(type, group);
          // eslint-disable-next-line no-await-in-loop
          await grantGroupPrivileges(type, group);
        }
      }
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.rbacRoles],
      });

      await grantRoleToUsers();
      putStatusMessage({
        message: t("roles.create_modal.success"),
        type: StatusMessageType.Success,
      });
      onFinished();
    } catch (error: any) {
      console.log(error);
      putStatusMessage({
        message: error.message,
        type: StatusMessageType.Error,
      });
      if (roleCreated) {
        await deleteRole(name);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Step
      title={t("roles_wizard.assign.title")}
      subtitle={t("roles_wizard.assign.subtitle")}
      mainActionTitle={t("roles_wizard.assign.create")}
      body={body}
      onClose={onClose}
      onSubmit={onFormSubmit}
      isLoading={isLoading}
      activeStepIndex={activeStepIndex}
      onPrevStep={onPrevStep}
      disabledSubmit={isLoading || !selectedUsers.length}
      titleTestId="roles-wizard-assign-user-title"
    />
  );
};
