import {
  QueryStatementResult,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

const documentHasResults = (
  document: WorkspaceDocument,
  qsResults: {
    [qsId: string]: QueryStatementResult | undefined;
  }
) => {
  const execution = document.execution;

  if (!execution) {
    return false;
  }

  const queryStatements = execution.queryStatements;

  if (!queryStatements || queryStatements.length === 0) {
    return false;
  }

  const someQueryHasResults = queryStatements.some(
    queryStatement => qsResults[queryStatement.id]
  );

  return someQueryHasResults;
};

export { documentHasResults };
