import {
  QueryStatementStatus,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

const documentHasErrors = (document: WorkspaceDocument) => {
  const execution = document.execution;

  if (!execution) {
    return false;
  }

  const queryStatements = execution.queryStatements;

  if (!queryStatements || queryStatements.length === 0) {
    return false;
  }

  const someQueryHasErrors = queryStatements.some(queryStatement => {
    return (
      queryStatement.error ||
      queryStatement.status === QueryStatementStatus.error
    );
  });

  return someQueryHasErrors;
};

export { documentHasErrors };
