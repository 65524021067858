import Toggle from "components/Toggle";

import styles from "./ColumnFilters.module.scss";

type Props = {
  name: string;
  title?: string;
  handleClick: (column: string) => void;
  checked: boolean;
  testId?: string;
};

const MenuItem = (props: Props) => {
  const { name, title, handleClick, testId, checked } = props;
  return (
    <div className={styles.menuItem}>
      <div
        className={styles.name}
        title={name}
      >
        {title || name}
      </div>
      <Toggle
        checked={checked}
        onChange={() => handleClick(name)}
        size="sm"
        dataTestId={testId || ""}
        classes={{ wrapper: styles.toggle }}
      />
    </div>
  );
};

export default MenuItem;
