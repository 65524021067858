import { Popover } from "@mui/material";
import React, { useEffect, useRef } from "react";

import DefinitionCodePreview from "components/DefinitionCodePreview/DefinitionCodePreview";

import styles from "./styles.module.scss";

interface ActiveCellProps {
  activeCell: {
    col: number;
    row: number;
  };
  getColumnWidth: (index: number) => number;
  columnLeftOffsets: number[];
  getRowHeight: () => number;
  getRawCell: (rowIndex: number, columnIndex: number) => any;
}

export const ActiveCell = (props: ActiveCellProps) => {
  const {
    activeCell,
    getColumnWidth,
    columnLeftOffsets,
    getRowHeight,
    getRawCell,
  } = props;
  const ref = useRef(null);
  const { col: columnIndex, row: rowIndex } = activeCell;
  const width = getColumnWidth(columnIndex);
  const left = columnLeftOffsets[columnIndex];
  const rowHeight = getRowHeight();
  const style = {
    width,
    height: rowHeight,
    left,
    top: rowIndex * rowHeight,
  };
  const { row, column } = getRawCell(rowIndex, columnIndex);
  const columnValue = row?.[column?.name];
  const type = column?._typeUpperCase;
  const isStructType = type?.includes("STRUCT");
  const [open, setOpen] = React.useState(false);

  const closeMenu = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isStructType) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isStructType) {
      setOpen(true);
    }
  }, [activeCell, isStructType]);

  if (!row || !column) {
    return null;
  }

  return (
    <>
      <div
        ref={ref}
        key="active"
        data-role="active"
        data-testid="active-cell"
        className={styles.active}
        style={style}
      />
      {isStructType && open && (
        <Popover
          onClose={closeMenu}
          open={Boolean(ref.current) && open}
          anchorEl={ref.current}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{
            root: styles.root,
          }}
        >
          <div className={styles.previewContainer}>
            <DefinitionCodePreview
              code={JSON.stringify(columnValue, null, 2)}
              showFooter={false}
              className={styles.popover}
            />
          </div>
        </Popover>
      )}
    </>
  );
};
