import classNames from "classnames";
import { useEffect } from "react";
import { SUPPORT_EMAIL } from "utils/constants";

import Button, { ButtonSize } from "components/Button";
import { ErrorScreen, ErrorScreenImagePosition } from "components/ErrorScreen";

import Compass from "./Compass";

import styles from "./styles.module.scss";

type Props = {
  onFinishRender?: () => void;
};

const AppCrash = (props: Props) => {
  const { onFinishRender } = props;

  useEffect(() => {
    onFinishRender && onFinishRender();
  }, [onFinishRender]);

  return (
    <ErrorScreen
      title="Something weird happened"
      image={<Compass />}
      imagePosition={ErrorScreenImagePosition.Top}
      classes={{
        image: classNames(styles.image, styles.forced, styles.forced2),
        footer: classNames(styles.footer, styles.forced),
      }}
      description={
        <>
          Contact&nbsp;
          <a
            href={`mailto:${SUPPORT_EMAIL}`}
            className={styles.link}
          >
            {SUPPORT_EMAIL}
          </a>
          &nbsp;if it happens again
        </>
      }
      footer={
        <div>
          <Button
            onClick={() => window.location.reload()}
            text="Reload the Page"
            size={ButtonSize.Medium}
          />
        </div>
      }
    />
  );
};

export default AppCrash;
