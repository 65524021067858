import { Popover } from "@mui/material";
import classNames from "classnames";
import { useRef } from "react";

import MoreVerticalIcon from "assets/icons/MoreVertical.svg?react";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import NestedContextMenuItem from "components/ContextMenu/NestedContextMenuItem";

import { RecordMenuItemGroup } from "./types";

import styles from "../styles.module.scss";

const stopPropagation = (event: React.SyntheticEvent) =>
  event.stopPropagation();

type Props = {
  items: RecordMenuItemGroup;
  menu: ReturnType<typeof useMenu>;
  testId?: string;
  alwaysVisible?: boolean;
};

export const Menu = (props: Props) => {
  const { alwaysVisible, items, menu, testId } = props;
  const { openMenu, closeMenu, menuElement, iconElement } = menu;

  const popoverRef = useRef<HTMLDivElement>(null);

  if (!items.length) {
    return null;
  }

  const menuItems = items.map(group => {
    return group.map(item => {
      const { key, text, action, renderContent, shortcut, disabled, testId } =
        item;

      if (renderContent) {
        if (!menuElement) {
          return null;
        }

        return (
          <NestedContextMenuItem
            key={key}
            text={text}
            testId={testId}
            anchorElement={popoverRef}
            renderContent={renderContent}
            contentPaperStyle={{ width: 480 }}
            onContentClose={closeMenu}
          />
        );
      }

      const handleClick = () => {
        closeMenu();
        action?.();
      };

      return (
        <ContextMenuItem
          key={key}
          text={text}
          onClick={handleClick}
          secondaryText={shortcut}
          disabled={disabled}
          testId={testId}
        />
      );
    });
  });

  return (
    <div
      onClick={stopPropagation}
      onMouseOver={stopPropagation}
      className={styles.action}
    >
      <div
        className={styles.menuIconContainer}
        ref={iconElement}
        onClick={openMenu}
        data-testid={testId}
      >
        <MoreVerticalIcon
          className={classNames(styles.action__icon, {
            [styles.visible]: alwaysVisible,
            [styles.activeActionIcon]: !!menuElement,
          })}
        />
      </div>

      {Boolean(menuElement) && (
        <Popover
          onClose={closeMenu}
          open
          anchorEl={menuElement}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          role="menu"
        >
          <div ref={popoverRef}>
            <ContextMenu>
              {menuItems.reduce((acc, item, index) => {
                if (index > 0) {
                  acc.push(<ContextMenuDivider key="divider" />, ...item);
                } else {
                  acc.push(...item);
                }
                return acc;
              })}
            </ContextMenu>
          </div>
        </Popover>
      )}
    </div>
  );
};
