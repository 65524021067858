import cn from "classnames";

import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";

import ErrorMessage from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/ErrorMessage/ErrorMessage";
import ResultsControls from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/ResultsControls/ResultsControls";
import { RunningQuery } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/RunningQuery/RunningQuery";
import TabsBar from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/TabsBar/TabsBar";
import OutputSubPanel from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/components/OutputSubPanel/OutputSubPanel";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { isDocumentRunning } from "pages/DevelopWorkspace/helpers/isDocumentRunning";
import {
  DocumentOutputTab,
  ExecutionType,
  QueryStatement,
  QueryStatementStatus,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import { ExplainStatement } from "../ExplainStatement/ExplainStatement";
import { Datagrid } from "./Table/Datagrid";

import styles from "./DocumentResults.module.scss";

interface DocumentResultsProps {
  document: WorkspaceDocument;
}

const EMPTY_RESULT_PLACEHOLDER = {
  data: [{ "": "The query has been executed successfully.", No: 1 }],
  fields: [
    {
      name: "No",
      type: "",
      displayName: "No",
    } as any,
    {
      name: "",
      type: "string",
    },
  ],
};

const QUERY_STATUSES_VISIBLE_IN_RESULTS = [
  QueryStatementStatus.error,
  QueryStatementStatus.success,
  QueryStatementStatus.cancelled,
];

const DocumentResults = (props: DocumentResultsProps) => {
  const { document } = props;
  const {
    actions: { switchDocumentActiveQueryStatement },
    qsResults,
  } = useDocuments();
  const { data: engines } = useWorkspaceEngines({ includeSystemEngine: true });

  if (!document) {
    return null;
  }

  if (!document.execution) {
    return null;
  }

  const documentIsRunning = isDocumentRunning(document);

  const activeQueryStatementIndex =
    document.execution.activeQueryStatementIndex;

  const activeQueryStatement =
    document.execution.queryStatements[activeQueryStatementIndex];

  const visibleQueryStatements = document.execution.queryStatements.filter(
    qs => {
      const qsResult = qsResults[qs.id];

      return (
        QUERY_STATUSES_VISIBLE_IN_RESULTS.includes(qs.status) &&
        (qsResult?.data || qs.error)
      );
    }
  );

  const renderQueryResults = (
    queryStatement: QueryStatement,
    isActive: boolean
  ) => {
    const qsResult = qsResults[queryStatement.id];

    if (!(qsResult?.data || queryStatement.error)) {
      return null;
    }

    if (
      queryStatement.status === QueryStatementStatus.error &&
      queryStatement.error
    ) {
      const message =
        typeof queryStatement.error === "string"
          ? queryStatement.error
          : "An error occurred";

      return <ErrorMessage errorMessage={message} />;
    }

    if (!qsResult?.data) {
      return null;
    }

    const result = qsResult.data;
    const meta = qsResult.meta;

    if (
      isActive &&
      document.execution?.executionType === ExecutionType.Explain
    ) {
      return (
        <ExplainStatement
          document={document}
          queryStatement={queryStatement}
        />
      );
    }
    if (meta?.length === 0) {
      return (
        <Datagrid
          data={EMPTY_RESULT_PLACEHOLDER.data}
          fields={EMPTY_RESULT_PLACEHOLDER.fields}
          isActive={isActive}
        />
      );
    }

    if (Array.isArray(result) && Array.isArray(meta)) {
      return (
        <Datagrid
          data={result}
          fields={meta}
          isActive={isActive}
        />
      );
    }
  };

  const renderBody = () => {
    return visibleQueryStatements.map((queryStatement, index) => {
      const isActive = index === activeQueryStatementIndex;
      return (
        <div
          key={queryStatement.id}
          className={cn(styles.resultsContainer, {
            [styles.active]: isActive,
          })}
        >
          {renderQueryResults(queryStatement, isActive)}
        </div>
      );
    });
  };

  const handleSetActiveTab = (index: number) => {
    switchDocumentActiveQueryStatement({
      documentId: document.id,
      queryStatementIndex: index,
      userSelectedActiveQueryStatementIndexTimestamp: Date.now(),
      activeOutputTab: DocumentOutputTab.Results,
    });
  };

  const currentEngine = engines?.find(
    engine => engine.engineName === document.context.engineName
  );

  return (
    <div className={styles.root}>
      {documentIsRunning &&
        !visibleQueryStatements.length &&
        (currentEngine ? (
          <RunningQuery
            queryStatement={activeQueryStatement}
            engine={currentEngine}
          />
        ) : null)}

      {!!visibleQueryStatements.length && (
        <OutputSubPanel
          body={
            <TabsBar
              queryStatements={visibleQueryStatements}
              activeQueryStatementIndex={activeQueryStatementIndex}
              setActiveTab={handleSetActiveTab}
            />
          }
          controls={
            activeQueryStatement && (
              <ResultsControls
                queryStatement={activeQueryStatement}
                document={document}
              />
            )
          }
        />
      )}
      <div className={styles.body}>{renderBody()}</div>
    </div>
  );
};

export default DocumentResults;
