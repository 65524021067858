import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import z from "zod";

import { Modal } from "components/Modal/Modal";
import { TextInput } from "components/TextInput/TextInput";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
  onSubmit: (database: { databaseName: string; description: string }) => void;
  isLoading: boolean;
};

const schema = z.object({
  databaseName: z
    .string()
    .max(64, { message: "Must be 64 or fewer characters long" })
    .regex(/^[0-9a-zA-Z_-]+$/, {
      message: "Use letters, numbers, underscore",
    })
    .regex(/^[^0-9_-]/, {
      message: "Name can not start with number or underscore",
    })
    .regex(/[^_-]$/, {
      message: "Name can not end with underscore or hyphen",
    })
    .default(""),
  description: z
    .string()
    .max(1024, { message: "Must be 1024 or fewer characters long" })
    .default(""),
});

type Fields = z.infer<typeof schema>;

export const DatabaseModal = (props: Props) => {
  const { onClose, isLoading, onSubmit } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Fields>({
    resolver: zodResolver(schema),
    defaultValues: { databaseName: "", description: "" },
  });

  const { t } = useTranslation();

  const onFormSubmit = (data: Fields) => {
    onSubmit(data);
  };

  return (
    <Modal
      title={t("workspace.create_database.title")}
      onSubmit={handleSubmit(onFormSubmit)}
      primaryButton="workspace.create_database.submit"
      onClose={onClose}
      isLoading={isLoading}
      disabledSubmit={isLoading || !isDirty}
    >
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <div className={styles.inputContainer}>
            <TextInput
              testId="database-name-input"
              inputRootClassName={styles.nameInput}
              {...register("databaseName")}
              error={!!errors?.databaseName}
              helperText={errors?.databaseName?.message}
              label={
                <div className={classNames(styles.label, styles.required)}>
                  {t("workspace.create_database.name")}
                </div>
              }
              required
              disabled={isLoading}
              autoFocus
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.inputContainer}>
            <TextInput
              disabled={isLoading}
              testId="database-description-input"
              inputRootClassName={styles.nameInput}
              {...register("description")}
              label={
                <div className={styles.label}>
                  {t("workspace.create_database.description")}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
