import { useLoginId } from "hooks/useLoginId";
import { useOrganization } from "hooks/useOrganization";

import { Editor } from "pages/DevelopWorkspace/Editor/Editor";
import ActiveEditorViewContextProvider from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/ActiveEditorViewContext";
import { DocumentsContextProvider } from "pages/DevelopWorkspace/contexts/DocumentsContext/DocumentsContext";
import { SQLynxLoader } from "pages/DevelopWorkspace/contexts/SQLynxContext/SQLynxLoader";
import { ScriptsContextProvider } from "pages/DevelopWorkspace/contexts/ScriptsContext/ScriptsContext";
import { ShortcutsOverlayProvider } from "pages/DevelopWorkspace/contexts/ShortcutsOverlayContext/ShortcutsOverlayContext";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import LeftSidebar from "components/LeftSidebar/LeftSidebar";

import styles from "./styles.module.scss";

export const Workspace = () => {
  const { getAccount } = useCurrentAccount();
  const { loginId } = useLoginId();
  const { data: organization } = useOrganization();

  const account = getAccount();

  if (!account || !loginId || !organization) {
    return null;
  }

  return (
    <ScriptsContextProvider>
      <DocumentsContextProvider
        account={account}
        loginId={loginId}
        organizationName={organization.organizationName}
      >
        <ActiveEditorViewContextProvider>
          <ShortcutsOverlayProvider>
            <div className={styles.wrapper}>
              <div className={styles.container}>
                <LeftSidebar />
                <div className={styles.content}>
                  <SQLynxLoader>
                    <Editor />
                  </SQLynxLoader>
                </div>
              </div>
            </div>
          </ShortcutsOverlayProvider>
        </ActiveEditorViewContextProvider>
      </DocumentsContextProvider>
    </ScriptsContextProvider>
  );
};
