import classNames from "classnames";
import { useTranslation } from "react-i18next";

import SearchInput from "components/SearchInput/SearchInput";

import styles from "./ColumnFilters.module.scss";

interface SearchProps {
  value: string;
  placeholder?: string;
  classes?: { root?: string; search?: string };
  onChange: (value: string) => void;
}
export const Search = (props: SearchProps) => {
  const { value, onChange, placeholder, classes } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.search, classes?.root)}>
      <div className={classNames(styles.search__root, classes?.search)}>
        <SearchInput
          testId="filter-search"
          className={styles.search__root}
          inputClassName={styles.search__input}
          autoFocus
          onChange={onChange}
          placeholder={placeholder ?? t("filters.search")}
          defaultValue={value}
          iconClassName={styles.search__icon}
        />
      </div>
    </div>
  );
};
