import { EXECUTION_FINAL_STATUSES } from "pages/DevelopWorkspace/contexts/DocumentsContext/constants";
import {
  DocumentsState,
  QueryStatement,
  QueryStatementStatus,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

const getStatusToPersist = (
  context: WorkspaceDocument["context"],
  queryStatement: QueryStatement
) => {
  if (EXECUTION_FINAL_STATUSES.includes(queryStatement.status)) {
    return queryStatement.status;
  }

  if (queryStatement.status === QueryStatementStatus.pending) {
    return QueryStatementStatus.pending;
  }

  if (
    queryStatement.status === QueryStatementStatus.running &&
    context.engineName !== "system"
  ) {
    return QueryStatementStatus.unknown;
  }
  return QueryStatementStatus.cancelled;
};

const persistFilter = (state: DocumentsState) => {
  const nextDocuments = state.documents.map(document => {
    if (!document.execution) {
      return document;
    }

    return {
      ...document,
      execution: {
        ...document.execution,
        cancellationStatus: undefined, // reset cancellation status
        queryStatements: document.execution.queryStatements.map(
          queryStatement => {
            const status = getStatusToPersist(document.context, queryStatement);

            return {
              ...queryStatement,
              status,
              error: null,
            };
          }
        ),
      },
    };
  });

  return {
    ...state,
    documents: nextDocuments,
  };
};

export { persistFilter };
