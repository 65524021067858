import Toggle from "components/Toggle";

import styles from "./ColumnFilters.module.scss";

type Props = {
  checked: boolean;
  text: string;
  handleClick: () => void;
};

const ShowAllToggle = (props: Props) => {
  const { checked, handleClick, text } = props;

  return (
    <div className={styles.showAll}>
      <div
        className={styles.text}
        title={text}
      >
        {text}
      </div>
      <Toggle
        checked={checked}
        onChange={() => handleClick()}
        dataTestId="show-all-columns-toggle"
        size="sm"
        classes={{ wrapper: styles.toggle }}
      />
    </div>
  );
};

export default ShowAllToggle;
