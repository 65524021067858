import {
  QueryMetrics,
  UtilMetrics,
} from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/types";

export enum TimeGrouping {
  Default = "default",
  Minute = "minute",
  Hour = "hour",
  Day = "day",
}

export const RESULTS_COLUMNS_MAP = {
  [UtilMetrics.CPU]: "cpu_avg",
  [UtilMetrics.Memory]: "memory_avg",
  [UtilMetrics.Disk]: "disk_avg",
  [UtilMetrics.Cache]: "cache_avg",
  [QueryMetrics.RunningQueries]: "running_queries",
  [QueryMetrics.SuspendedQueries]: "suspended_queries",
  [QueryMetrics.SpilledBytes]: "spilled_bytes",
  date: "timestamp_c",
};

export enum EngineMonitoringTimeWindow {
  FiveMinutes = "5 minutes",
  OneHour = "1 hour",
  OneDay = "1 day",
  OneWeek = "1 week",
  OneMonth = "1 month",
}

export const TIME_WINDOWS = [
  {
    label: "engine_monitoring.time_windows.5_minutes",
    value: EngineMonitoringTimeWindow.FiveMinutes,
    timeGrouping: TimeGrouping.Default,
    minEngineRunTimeSec: null,
  },
  {
    label: "engine_monitoring.time_windows.hour",
    value: EngineMonitoringTimeWindow.OneHour,
    timeGrouping: TimeGrouping.Minute,
    minEngineRunTimeSec: null,
  },
  {
    label: "engine_monitoring.time_windows.day",
    value: EngineMonitoringTimeWindow.OneDay,
    timeGrouping: TimeGrouping.Hour,
    minEngineRunTimeSec: 3600,
  },
  {
    label: "engine_monitoring.time_windows.week",
    value: EngineMonitoringTimeWindow.OneWeek,
    timeGrouping: TimeGrouping.Hour,
    minEngineRunTimeSec: 3600 * 24,
  },
  {
    label: "engine_monitoring.time_windows.month",
    value: EngineMonitoringTimeWindow.OneMonth,
    timeGrouping: TimeGrouping.Day,
    minEngineRunTimeSec: 3600 * 24 * 2,
  },
];

export const UTIL_METRICS = {
  [UtilMetrics.CPU]: "engine_monitoring.metrics.cpu",
  [UtilMetrics.Memory]: "engine_monitoring.metrics.memory",
  [UtilMetrics.Disk]: "engine_monitoring.metrics.disk",
  [UtilMetrics.Cache]: "engine_monitoring.metrics.cache",
};

export const QUERY_METRICS = {
  [QueryMetrics.RunningQueries]: "engine_monitoring.metrics.running_queries",
  [QueryMetrics.SuspendedQueries]:
    "engine_monitoring.metrics.suspended_queries",
  [QueryMetrics.SpilledBytes]: "engine_monitoring.metrics.spilled_bytes",
};

export const UTIL_METRICS_COLORS = {
  [UtilMetrics.CPU]: "#9867D8",
  [UtilMetrics.Memory]: "#55E3D2",
  [UtilMetrics.Disk]: "#FFBB55",
  [UtilMetrics.Cache]: "#D2669B",
};

export const QUERY_METRICS_COLORS = {
  [QueryMetrics.RunningQueries]: "#FF484E",
  [QueryMetrics.SuspendedQueries]: "#9867D8",
  [QueryMetrics.SpilledBytes]: "#D2669B",
};
