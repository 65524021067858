import { systemEngineEnvironment } from "services/environment/systemEngine";

type Schema = {
  schemaName: string;
};
export const getSchemaNames = async (database: string) => {
  const result = await systemEngineEnvironment.execute<Schema>(
    `select schema_name from information_schema.schemata`,
    { database }
  );

  const [response] = result;
  return response.rows;
};
