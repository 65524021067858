import {
  CHANGE_FILTERS,
  HIDE_FILTERS,
  INCLUDE,
  RESET_ALL,
  SELECT_ALL,
  SHOW_FILTERS,
  UNSELECT_ALL,
} from "components/Datagrid/Filters/actions";

const mapItem = (item: any) => item.value;

export const filtersReducer = (
  state: any,
  action: {
    type: string;
    autoApply?: boolean;
    payload?: any;
    selectedItem?: {
      value: string;
      label: string;
      count: number;
    };
    items?: {
      value: string;
      label: string;
      count: number;
    }[];
    filters?: {
      hidden: boolean;
      include: {
        items: {
          value: string;
          label: string;
          count: number;
        };
      };
    };
  }
) => {
  const prevState = {
    ...state,
    autoApply: !!action.autoApply,
  };

  switch (action.type) {
    case RESET_ALL: {
      return {
        ...prevState,
        include: {
          items: [],
          itemsSet: new Set(),
        },
        filters: undefined,
      };
    }
    case SELECT_ALL: {
      return {
        ...prevState,
        include: {
          items: [],
          itemsSet: new Set(),
        },
      };
    }
    case UNSELECT_ALL: {
      return {
        ...prevState,
        include: {
          items: [],
          itemsSet: new Set(),
        },
      };
    }
    case INCLUDE: {
      const { selectedItem } = action;
      const { include } = prevState;

      const hasItem = include.items.find((item: any) => {
        return item.value === selectedItem?.value;
      });

      if (hasItem) {
        const itemsWithout = include.items.filter((item: any) => {
          return item.value !== selectedItem?.value;
        });
        return {
          ...prevState,
          include: {
            items: itemsWithout,
            itemsSet: new Set(itemsWithout.map(mapItem)),
          },
        };
      }
      const items = [...include.items, selectedItem];
      return {
        ...prevState,
        include: {
          items,
          itemsSet: new Set(items.map(mapItem)),
        },
      };
    }
    case CHANGE_FILTERS: {
      const { filters } = action;
      return {
        ...prevState,
        filters,
      };
    }
    case HIDE_FILTERS: {
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          hidden: true,
        },
      };
    }
    case SHOW_FILTERS: {
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          hidden: false,
        },
      };
    }
    default:
      return prevState;
  }
};
