import BigNumber from "bignumber.js";
import { getNavigatorLanguage } from "utils/getNavigatorLanguage";

import { isDateType, isNumberType } from "components/Datagrid/utils/data";

import { MAX_DISPLAY_LENGTH } from "../constant";

const language = getNavigatorLanguage();
const formatter = new Intl.NumberFormat(language, {
  minimumSignificantDigits: 1,
});

const groupSeparator = (1000).toLocaleString(language).substring(1, 2);
const decimalSeparator = (0.1).toLocaleString(language).substring(1, 2);

export const formatBigNumber = (value: {
  toFormat: (arg0: {
    decimalSeparator: string;
    groupSeparator: string;
    groupSize: number;
  }) => any;
}) => {
  return value.toFormat({
    decimalSeparator,
    groupSeparator,
    groupSize: 3,
  });
};

export const limitValue = (value: string) => {
  return value.length > MAX_DISPLAY_LENGTH
    ? value.slice(0, MAX_DISPLAY_LENGTH) + "..."
    : value;
};

export const formatNumber = (value: string | BigNumber | number) => {
  if (typeof value === "string") {
    return value;
  }
  if (BigNumber.isBigNumber(value)) {
    return formatBigNumber(value);
  }
  return formatter.format(value);
};

const formatArray = ({
  value,
  type,
  formatCellFunction,
  shouldLimitValueLength,
}: {
  value: string;
  type: string;
  shouldLimitValueLength?: boolean;
  formatCellFunction: (options: {
    value: null | string;
    type: string;
    stringifyStrings?: boolean;
    shouldLimitValueLength?: boolean;
  }) => string;
}) => {
  const innerType = type.substring(
    type.indexOf("(") + 1,
    type.lastIndexOf(")")
  );
  let formatedValue = "[";
  for (let i = 0; i < value.length; i++) {
    const item = value[i];
    const isLast = i === value.length - 1;
    formatedValue += formatCellFunction({
      value: item,
      type: innerType,
      stringifyStrings: true,
      shouldLimitValueLength,
    });
    if (!isLast) {
      formatedValue += ", ";
    }
  }

  formatedValue = shouldLimitValueLength
    ? limitValue(formatedValue)
    : formatedValue;

  formatedValue += "]";

  return formatedValue;
};

const stringNumberFitsNumber = (value: string) => {
  const test = new BigNumber(value);

  return test.toString() === `${+value}`;
};

const formatNumberCell = (value: {}) => {
  if (typeof value === "string" && !stringNumberFitsNumber(value)) {
    return formatBigNumber(new BigNumber(value));
  }

  const isBigNumber = typeof value === "object" && BigNumber.isBigNumber(value);
  if (isBigNumber) {
    return formatBigNumber(value);
  }

  const numberValue = +value;
  if (!Number.isNaN(numberValue)) {
    return formatter.format(numberValue);
  }
};

const formatCellValue = ({
  value,
  type,
  stringifyStrings,
  shouldLimitValueLength,
}: {
  value: string | null | undefined;
  type: string | undefined;
  stringifyStrings?: boolean;
  shouldLimitValueLength?: boolean;
}): string => {
  if (value == null) {
    return JSON.stringify(value);
  }

  if (type?.startsWith("ARRAY")) {
    return formatArray({
      value,
      type,
      formatCellFunction: formatCellValue,
      shouldLimitValueLength,
    });
  }

  if (isNumberType(type)) {
    const cellValue = formatNumberCell(value);

    if (cellValue !== undefined) return cellValue;
  }

  if (isDateType(type)) {
    return value;
  }

  if (["object", "boolean"].includes(typeof value)) {
    return JSON.stringify(value);
  }

  if (stringifyStrings && typeof value === "string") {
    if (value.includes('"')) {
      return "'" + value + "'";
    }
    return JSON.stringify(value);
  }

  if (typeof value === "string") {
    return shouldLimitValueLength ? limitValue(value) : value;
  }

  return value;
};

const formatCellValueForEditing = ({
  value,
  type,
  stringifyStrings,
}: {
  value: string | null;
  type: string;
  stringifyStrings?: boolean;
}) => {
  if (value === null || typeof value === "boolean") {
    return JSON.stringify(value);
  }
  if (type.startsWith("ARRAY")) {
    return formatArray({
      value,
      type,
      formatCellFunction: formatCellValueForEditing,
    });
  }
  if (BigNumber.isBigNumber(value)) {
    return value.toString(10);
  }

  if (stringifyStrings && typeof value === "string") {
    if (value.includes('"')) {
      return "'" + value + "'";
    }
    return JSON.stringify(value);
  }
  return value;
};

export const formatCell = ({
  row,
  column,
  shouldLimitValueLength = true,
}: {
  row: any;
  column: any;
  shouldLimitValueLength?: boolean;
}) => {
  const columnValue = row?.[column?.name];
  const type = column?._typeUpperCase;
  const formatedValue = formatCellValue({
    value: columnValue,
    type,
    shouldLimitValueLength,
  });

  return { value: formatedValue };
};

export const formatCellForEditing = (row: any, column: any) => {
  const value = row[column.name];
  const type = column._typeUpperCase;
  return formatCellValueForEditing({ value, type });
};
