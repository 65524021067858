import { isMac } from "services/browser";

import { Shortcut } from "pages/DevelopWorkspace/services/shortcuts/types";

export const getShortcutHint = (shortcut: Shortcut) => {
  if (isMac) {
    return shortcut.mac.hint;
  }

  return shortcut.windows.hint;
};
