import { useCallback } from "react";

import { useIsFirstUser } from "./useIsFirstUser";

const onboardedValue = "onboarded";

const handledGetFromLocalStorage = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

const handledSetToLocalStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.error(e);
  }
};

export const useFirstTimeExperience = ({
  loginId,
  organizationName,
  hasExistingScripts,
}: {
  loginId: string;
  organizationName: string;
  hasExistingScripts: boolean;
}) => {
  const isFirstUser = useIsFirstUser();
  const key = `${loginId}-${organizationName}-consumed-first-time-template`;
  // secondary user is any user that is not the first ever user created in the organization
  const isSecondaryUser = hasExistingScripts || !isFirstUser;

  // ensure current users don't have this fallback
  if (isSecondaryUser && !handledGetFromLocalStorage(key)) {
    handledSetToLocalStorage(key, onboardedValue);
  }

  const onboarded = handledGetFromLocalStorage(key);

  const isFirstTimeExperience = !onboarded;

  const onSampleScriptGenerated = useCallback(() => {
    handledSetToLocalStorage(key, onboardedValue);
  }, [key]);

  return {
    shouldGenerateSampleScript: isFirstTimeExperience,
    onSampleScriptGenerated,
  };
};
