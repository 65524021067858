import { AuthService } from "services/auth";
import { WorkspaceEngine } from "services/engines/engine.types";

import { FIREBOLT_UI_USER_ENGINE_QUERY_LABEL } from "pages/DevelopWorkspace/services/constants";
import { buildEngineUrl } from "pages/DevelopWorkspace/services/helpers/buildEngineUrl";
import {
  FetchOptions,
  RunningQuery,
} from "pages/DevelopWorkspace/services/types";

const getRunningQuery = async (
  engine: WorkspaceEngine,
  queryId: string,
  fetchOptions: FetchOptions,
  authService: AuthService
) => {
  const token = await authService.getToken();
  if (!token) {
    throw new Error("Failed to fetch query progress: token is not available");
  }

  const { headers, abortController } = fetchOptions;

  const engineURL = new URL(`https://${engine.url}`);
  const url = buildEngineUrl(engineURL, {
    query_label: FIREBOLT_UI_USER_ENGINE_QUERY_LABEL,
    auto_start_stop_control: "ignore",
  });

  const response = await fetch(url, {
    signal: abortController.signal,
    method: "POST",
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      "Firebolt-Machine-Query": "1",
    },
    body: `select * from information_schema.engine_running_queries where query_id = '${queryId}'`,
  });

  const json = await response.json();

  const res = json.data[0];

  if (!res) {
    throw new Error("Query not found");
  }

  const runningQuery: RunningQuery = {
    status: res.status,
    queryLabel: res.query_label,
    queryId: res.query_id,
    durationUsec: res.duration_us,
    scannedRows: res.scanned_rows,
    scannedBytes: res.scanned_bytes,
    insertedRows: res.inserted_rows,
  };

  return runningQuery;
};

export default getRunningQuery;
