import { useSuspenseQuery } from "@tanstack/react-query";

import { ReactQueryKeysAccount } from "services/queryKeys";

import { getSchemaNames } from "./getSchemaNames";

type Schema = {
  schemaName: string;
};

export const useSchemaNames = ({
  database,
}: {
  database: string;
}): Schema[] => {
  const { data } = useSuspenseQuery<Schema[], Error>({
    queryKey: [ReactQueryKeysAccount.schemaNames, database],
    queryFn: async () => {
      const schemaNames = await getSchemaNames(database);
      return schemaNames;
    },
  });
  return data || [];
};
