import { useEffect } from "react";
import * as GoogleAnalytics from "react-ga";
import { useLocation } from "react-router-dom";

if (process.env.NODE_ENV !== "test") {
  GoogleAnalytics.initialize(process.env.REACT_APP_GA_TRACKING_ID as string);
}

type Options = {
  debug: true;
};

const trackPage = (page: string, options: Options) => {
  GoogleAnalytics.set({
    page,
    ...options,
  });
  GoogleAnalytics.pageview(page);
};

const withTracker = (
  Component: React.ComponentType<any>,
  options: Options = { debug: true }
) => {
  const Tracked = (props: any) => {
    const location = useLocation();
    useEffect(() => {
      const page = location.pathname;
      trackPage(page, options);
    }, [location.pathname]);

    return <Component {...props} />;
  };

  return Tracked;
};

export default withTracker;
