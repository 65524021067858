import _startCase from "lodash/startCase";
import { Trans, useTranslation } from "react-i18next";

import { bulkItemTitleMap } from "../PrivilegesTable/constant";
import {
  ROLES_WIZARD_ADD_GROUP_EMPTY,
  ROLES_WIZARD_IGNORE_ERROR,
  ROLES_WIZARD_SET_ASSIGN_PRIVILEGES,
} from "../privilegesReducer";
import { PermissionError, PrivilegesState } from "../types";
import { usePrivilegesState } from "../usePrivilegesContext";
import { MissingPermissionError } from "./MissingPermissionError";

type Props = {
  error: PermissionError;
  resource: string;
};

export const MissingDatabasePermission = (props: Props) => {
  const { t } = useTranslation();
  const { error, resource } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();

  const { errorSource } = error;
  const { name, actions } = errorSource;

  const addRelatedPermissions = () => {
    const { resource, action, object } = error;
    const resourceGroups = privilegesState[resource as keyof PrivilegesState];
    privilegesDispatch({
      type: ROLES_WIZARD_ADD_GROUP_EMPTY,
      resource: "database",
      objects: [object],
    });

    privilegesDispatch({
      type: ROLES_WIZARD_SET_ASSIGN_PRIVILEGES,
      resource: "database",
      privileges: [action],
      index: resourceGroups.length,
    });
  };

  const ignorePermissionError = () => {
    privilegesDispatch({
      type: ROLES_WIZARD_IGNORE_ERROR,
      resource,
      error,
    });
  };

  const isBulk = bulkItemTitleMap[name as keyof typeof bulkItemTitleMap];
  const resourceName = isBulk
    ? t(bulkItemTitleMap[name as keyof typeof bulkItemTitleMap])
    : name;

  return (
    <MissingPermissionError
      addRelatedPermissions={addRelatedPermissions}
      ignorePermissionError={ignorePermissionError}
      primaryActionText={t("roles_wizard.missing_permission.add")}
      message={t("roles_wizard.missing_permission.message_database")}
      messagePermission={
        <Trans
          i18nKey="roles_wizard.missing_permission.message_permission"
          values={{
            permission: actions
              .map(action => _startCase(action.toLocaleLowerCase()))
              .join(", "),
            resource: resourceName,

            resourceType: isBulk ? "" : resource,
          }}
          components={{ bold: <strong />, br: <br /> }}
        />
      }
    />
  );
};
