import {
  CancellationStatus,
  DocumentEngineMonitoring,
  DocumentLayout,
  Execution,
  ExecutionContext,
  QueryStatement,
  SortOrder,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import {
  SwitchActiveOutputPayload,
  SwitchDocumentActiveQueryStatementPayload,
} from "./types";

export enum DocumentActionType {
  CHANGE_SORT_ORDER = "CHANGE_SORT_ORDER",
  ADD_DOCUMENT = "ADD_DOCUMENT",
  REMOVE_DOCUMENT = "REMOVE_DOCUMENT",
  REMOVE_ALL_DOCUMENTS = "REMOVE_ALL_DOCUMENTS",
  SET_ACTIVE_DOCUMENT = "SET_ACTIVE_DOCUMENT",
  CHANGE_DOCUMENT_CONTEXT = "CHANGE_DOCUMENT_CONTEXT",
  INIT_DOCUMENT_EXECUTION = "INIT_DOCUMENT_EXECUTION",
  CANCEL_DOCUMENT_EXECUTION = "CANCEL_DOCUMENT_EXECUTION",
  UPDATE_DOCUMENT_EXECUTION_CANCELLATION_STATUS = "UPDATE_DOCUMENT_EXECUTION_CANCELLATION_STATUS",
  UPDATE_DOCUMENT_QUERY_STATEMENT = "UPDATE_DOCUMENT_QUERY_STATEMENT",
  SWITCH_DOCUMENT_ACTIVE_QUERY_STATEMENT = "SWITCH_DOCUMENT_ACTIVE_QUERY_STATEMENT",
  SWITCH_ACTIVE_OUTPUT_TAB = "SWITCH_ACTIVE_OUTPUT_TAB",
  CHANGE_DOCUMENT_LAYOUT = "CHANGE_DOCUMENT_LAYOUT",
  UPDATE_DOCUMENT_SELECTION = "UPDATE_DOCUMENT_SELECTION",
  UPDATE_DOCUMENT_ENGINE_MONITORING = "UPDATE_DOCUMENT_ENGINE_MONITORING",
}

export type DocumentsAction =
  | {
      type: DocumentActionType.CHANGE_SORT_ORDER;
      payload: SortOrder;
    }
  | {
      type: DocumentActionType.ADD_DOCUMENT;
      payload: WorkspaceDocument;
    }
  | {
      type: DocumentActionType.SET_ACTIVE_DOCUMENT;
      payload: string;
    }
  | {
      type: DocumentActionType.REMOVE_DOCUMENT;
      payload: string; // uuid
    }
  | {
      type: DocumentActionType.REMOVE_ALL_DOCUMENTS;
      payload: {
        exceptDocumentIds: string[];
      };
    }
  | {
      type: DocumentActionType.CHANGE_DOCUMENT_CONTEXT;
      payload: {
        documentId: string;
        context: Partial<ExecutionContext>;
      };
    }
  | {
      type: DocumentActionType.INIT_DOCUMENT_EXECUTION;
      payload: {
        documentId: string;
        execution: Execution;
      };
    }
  | {
      type: DocumentActionType.UPDATE_DOCUMENT_QUERY_STATEMENT;
      payload: {
        documentId: string;
        id: string;
        queryStatement: Partial<Omit<QueryStatement, "prevStatus">>;
      };
    }
  | {
      type: DocumentActionType.CANCEL_DOCUMENT_EXECUTION;
      payload: string; // documentId
    }
  | {
      type: DocumentActionType.SWITCH_DOCUMENT_ACTIVE_QUERY_STATEMENT;
      payload: SwitchDocumentActiveQueryStatementPayload;
    }
  | {
      type: DocumentActionType.SWITCH_ACTIVE_OUTPUT_TAB;
      payload: SwitchActiveOutputPayload;
    }
  | {
      type: DocumentActionType.CHANGE_DOCUMENT_LAYOUT;
      payload: {
        documentId: string;
        layout: Partial<DocumentLayout>;
      };
    }
  | {
      type: DocumentActionType.UPDATE_DOCUMENT_SELECTION;
      payload: {
        documentId: string;
        selection: [number, number];
      };
    }
  | {
      type: DocumentActionType.UPDATE_DOCUMENT_EXECUTION_CANCELLATION_STATUS;
      payload: {
        documentId: string;
        queryStatementId: string;
        cancellationStatus: CancellationStatus;
      };
    }
  | {
      type: DocumentActionType.UPDATE_DOCUMENT_ENGINE_MONITORING;
      payload: {
        documentId: string;
        engineMonitoring: DocumentEngineMonitoring;
      };
    };
