import React, { useCallback, useMemo } from "react";

interface ShortcutsOverlayContextValue {
  open: boolean;
  showOverlay: () => void;
  closeOverlay: () => void;
}

const DEFAULT_CONTEXT_VALUE = {
  open: false,
  showOverlay: () => {},
  closeOverlay: () => {},
};

const ShortcutsOverlayContext =
  React.createContext<ShortcutsOverlayContextValue>(DEFAULT_CONTEXT_VALUE);

interface ShortcutsOverlayProviderProps {
  children: React.ReactNode;
}

const ShortcutsOverlayProvider = (props: ShortcutsOverlayProviderProps) => {
  const { children } = props;

  const [open, setOpen] = React.useState(false);

  const showOverlay = useCallback(() => {
    setOpen(true);
  }, []);
  const closeOverlay = useCallback(() => {
    setOpen(false);
  }, []);

  const value = useMemo(() => {
    return {
      open,
      showOverlay,
      closeOverlay,
    };
  }, [open, showOverlay, closeOverlay]);

  return (
    <ShortcutsOverlayContext.Provider value={value}>
      {children}
    </ShortcutsOverlayContext.Provider>
  );
};

const useShortcutsOverlay = () => {
  const context = React.useContext(ShortcutsOverlayContext);

  return context;
};

export {
  ShortcutsOverlayContext,
  ShortcutsOverlayProvider,
  useShortcutsOverlay,
};
