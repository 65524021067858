import classNames from "classnames";
import _startCase from "lodash/startCase";
import { useTranslation } from "react-i18next";

import RevokeIcon from "assets/icons/Revoke.svg?react";

import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import { PrivilegesState } from "../types";
import { usePrivilegesState } from "../usePrivilegesContext";
import { CLEAR_ALL } from "./constant";

import styles from "./styles.module.scss";

type Props = {
  onChange: (privileges: string[]) => void;
  privileges: { text: string; value: string }[];
  resource: string;
  index: number;
};

export const RevokePrivilegeSelector = (props: Props) => {
  const { t } = useTranslation();
  const { onChange, privileges, index, resource } = props;

  const { privilegesState } = usePrivilegesState();
  const groupState = privilegesState[resource as keyof PrivilegesState][index];
  const { toAssign, toRevoke } = groupState;

  const items = [
    <ContextMenuItem
      value={CLEAR_ALL}
      checkedIconPlaceholder={true}
      checkedOptions={{ indeterminate: true }}
      text={t("roles_wizard.privileges.clear_all")}
      bulkItem
      direction="left"
      skipFilter
      key="all"
    />,
    <ContextMenuDivider key="divider" />,
    ...privileges.map(privilege => {
      const { text, value } = privilege;
      const disabled = toAssign.includes(value);
      const disabledTooltip = disabled
        ? t("roles_wizard.privileges.disabled_grant_privilege_tooltip", {
            privilege: text,
          })
        : undefined;
      return (
        <ContextMenuItem
          key={value}
          value={value}
          checked={toRevoke.includes(value)}
          checkedIconPlaceholder={true}
          text={text}
          direction="left"
          disabled={disabled}
          disabledTooltip={disabledTooltip}
        />
      );
    }),
  ];

  return null;
  // eslint-disable-next-line
  return (
    <OutlinedSelect
      multiple
      controlledValue={toRevoke}
      onSelect={(items: string[]) => {
        const [item] = items;
        item === CLEAR_ALL ? onChange([]) : onChange(items);
      }}
      allowUncheck
      searchOptions={{
        searchPlaceholder: t("roles_wizard.privileges.search"),
      }}
      renderValue={items => {
        return (
          <div
            className={classNames(styles.grant, {
              [styles.hasRevoke]: items.length,
            })}
          >
            <RevokeIcon />
            {items.length
              ? items
                  .map(item =>
                    _startCase(item.toLowerCase().replace("usage", "use"))
                  )
                  .join(" ")
              : t("roles_wizard.privileges.no_permissions")}
          </div>
        );
      }}
      wrapperClassName={styles.select}
      noBorder
      testId={`roles-wizard-revoke-privileges-${resource}-${index}"`}
    >
      {items}
    </OutlinedSelect>
  );
};
