import { CircularProgress, Popover } from "@mui/material";
import cn from "classnames";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import RunIconArrow from "assets/icons/RunIconArrow.svg?react";
import RunIconDefault from "assets/icons/RunIconDefault.svg?react";
import RunIconStop from "assets/icons/RunIconStop.svg?react";

import { ExecutionType } from "pages/DevelopWorkspace/workspace.types";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { ExplainIcon } from "components/Icons";

import styles from "./RunButton.module.scss";

interface RunButtonProps {
  onClick: () => void;
  isRunning: boolean;
  isUnknown?: boolean;
  isRestoring?: boolean;
  isCanceling: boolean;
  executionType: ExecutionType;
  handleChangeExecutionType: (type: ExecutionType) => void;
}

const iconMap = {
  query: RunIconDefault,
  explain: ExplainIcon,
};

const RunButton = (props: RunButtonProps) => {
  const {
    onClick,
    isRunning,
    isCanceling,
    isRestoring,
    isUnknown,
    handleChangeExecutionType,
    executionType,
  } = props;
  const { menuElement, openMenu, closeMenu } = useMenu();
  const { t } = useTranslation();
  const button = useRef<HTMLDivElement | null>(null);
  const Icon = iconMap[executionType as keyof typeof iconMap];

  const renderIcon = () => {
    if (isRunning) {
      return (
        <div
          className={styles.iconWrapper}
          data-testid="query-in-progress-btn"
        >
          <div className={styles.progress}>
            <CircularProgress
              className={styles.progressBottom}
              size={19}
              thickness={4.5}
              variant="determinate"
              value={100}
            />

            <CircularProgress
              className={styles.progressTop}
              size={19}
              thickness={4.5}
            />

            <div className={styles.progressIcon} />
          </div>

          <RunIconStop className={styles.icon} />
        </div>
      );
    }

    return (
      <div className={styles.iconWrapper}>
        <Icon className={styles.icon} />
      </div>
    );
  };

  const handleChangeType = (type: ExecutionType) => () => {
    handleChangeExecutionType(type);
    closeMenu();
  };

  const handleMenuButtonClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    if (isRunning || isCanceling) {
      return;
    }

    openMenu(e);
  };

  const getButtonText = () => {
    if (isRunning) {
      return isCanceling
        ? t("workspace.run_button.canceling")
        : t("workspace.run_button.cancel");
    }

    return t("workspace.run_button.run");
  };

  const buttonText = getButtonText();

  return (
    <div
      className={cn(styles.root, {
        [styles.isRunning]: isRunning,
        [styles.isCanceling]: isCanceling,
        [styles.isUnknown]: isUnknown || isRestoring,
      })}
      ref={button}
    >
      <div
        className={styles.content}
        onClick={onClick}
        data-testid="execute-query-btn"
      >
        {renderIcon()}
        <div className={styles.text}>{buttonText}</div>
      </div>

      <div
        className={styles.arrowIconWrapper}
        onClick={handleMenuButtonClick}
        data-testid="open-execution-menu"
      >
        <RunIconArrow className={styles.icon} />
      </div>

      {!!menuElement && (
        <Popover
          open
          anchorEl={button.current}
          onClose={closeMenu}
          sx={{ transform: "translate(0px, 4px)" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              minWidth: 300,
            },
          }}
        >
          <ContextMenu>
            <ContextMenuItem
              text="Run query"
              onClick={handleChangeType(ExecutionType.Query)}
              checked={executionType === ExecutionType.Query}
              testId="execute-regular-query"
            />
            <ContextMenuItem
              text="Generate query plan"
              onClick={handleChangeType(ExecutionType.Explain)}
              checked={executionType === ExecutionType.Explain}
              testId="execute-generate-query-plan"
            />
          </ContextMenu>
        </Popover>
      )}
    </div>
  );
};

export default RunButton;
