import { Modifier } from "pages/DevelopWorkspace/services/shortcuts/types";

export const getModifiersFromEvent = (event: KeyboardEvent): Modifier[] => {
  const modifiers: Modifier[] = [];

  if (event.altKey) {
    modifiers.push(Modifier.altKey);
  }

  if (event.ctrlKey) {
    modifiers.push(Modifier.ctrlKey);
  }

  if (event.metaKey) {
    modifiers.push(Modifier.metaKey);
  }

  if (event.shiftKey) {
    modifiers.push(Modifier.shiftKey);
  }

  return modifiers;
};
