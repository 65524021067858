import { Popover } from "@mui/material";
import cn from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useMenu } from "components/ActionMenu/useMenu";
import { VerticalBarsIcon } from "components/Icons";

import MenuItem from "./MenuItem";
import { Search } from "./Search";
import ShowAllToggle from "./ShowAllToggle";

import styles from "./ColumnFilters.module.scss";

type Props = {
  columns: {
    name: string;
    displayName?: string;
  }[];
  visibleColumns: string[];
  handleColumnClick: (column: string) => void;
  toggleShowAllColumns?: () => void;
};

const ColumnFilters = (props: Props) => {
  const { columns, visibleColumns, handleColumnClick, toggleShowAllColumns } =
    props;
  const { t } = useTranslation();
  const { menuElement, openMenu, closeMenu, iconElement } = useMenu();
  const [columnSearchValue, setColumnSearchValue] = useState("");

  const filteredColumnsBySearch = columnSearchValue.length
    ? columns.filter(
        c =>
          c.name
            .toLocaleLowerCase()
            .includes(columnSearchValue.toLocaleLowerCase()) ||
          (!!c.displayName &&
            t(c.displayName)
              ?.toLocaleLowerCase()
              ?.includes(columnSearchValue.toLocaleLowerCase()))
      )
    : columns;

  return (
    <>
      <div
        className={cn(styles.iconContainer, {
          [styles.active]: menuElement,
        })}
        ref={iconElement}
        onClick={openMenu}
        data-testid="map-data-filter-columns-toggle"
      >
        <VerticalBarsIcon />
        {visibleColumns.length > 0 && (
          <div className={styles.appliedColumnFilterIndicator} />
        )}
      </div>
      {!!menuElement && (
        <Popover
          open={!!menuElement}
          anchorEl={menuElement}
          onClose={closeMenu}
          sx={{ transform: "translate(0px, -5px)" }}
          onClick={event => {
            event.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <div className={styles.columnFiltersPopup}>
            <Search
              classes={{
                root: styles.search,
                search: styles.search_container,
              }}
              placeholder={t("columns.select_columns")}
              value={columnSearchValue}
              onChange={value => setColumnSearchValue(value)}
            />
            {!!toggleShowAllColumns && (
              <ShowAllToggle
                checked={
                  visibleColumns.length === 0 ||
                  visibleColumns.length === columns.length
                }
                text={t("columns.show_all")}
                handleClick={toggleShowAllColumns}
              />
            )}
            <div className={styles.columns}>
              {filteredColumnsBySearch.map((c, index) => {
                return (
                  <MenuItem
                    name={c.name}
                    title={c.displayName ? t(c.displayName) : c.name}
                    checked={
                      !visibleColumns.length || visibleColumns.includes(c.name)
                    }
                    key={c.name}
                    testId={`map-data-filter-column-${index}`}
                    handleClick={handleColumnClick}
                  />
                );
              })}
            </div>
          </div>
        </Popover>
      )}
    </>
  );
};

export default ColumnFilters;
