import cn from "classnames";
import React, { useEffect, useRef } from "react";
import { Resizable } from "react-resizable";

import DocumentEditor from "pages/DevelopWorkspace/Editor/Document/DocumentContent/DocumentEditor/DocumentEditor";
import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { useScripts } from "pages/DevelopWorkspace/contexts/ScriptsContext/ScriptsContext";
import { getDocumentScript } from "pages/DevelopWorkspace/helpers/getDocumentScript";
import { WorkspaceDocument } from "pages/DevelopWorkspace/workspace.types";

import { useLayoutToggles } from "components/LayoutToggles/context";

import styles from "./DocumentContent.module.scss";

interface DocumentContentProps {
  document: WorkspaceDocument;
  runText: (content: string) => void;
}

const HEADER_HEIGHT_PX = 49;
const FOOTER_HEIGHT_PX = 49;

const adjustEditorHeight = (height?: number) => {
  // if height is not provided, use the 100%

  const editor = window.document.querySelector(".cm-editor");
  if (!editor) {
    return;
  }

  if (height) {
    editor.setAttribute("style", `height: ${height}px`);
  } else {
    editor.setAttribute("style", `height: 100%`);
  }
};

const DocumentContent = (props: DocumentContentProps) => {
  const { document, runText } = props;
  const { state: scriptsState } = useScripts();
  const {
    actions: {
      updateDocumentContent,

      updateDocumentSelection,
      changeDocumentLayout,
    },
  } = useDocuments();
  const { setActiveEditorView } = useActiveEditorView();

  const { layout } = useLayoutToggles();
  const editorHeightPx = document.layout.editorHeightPx;
  const rootRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState(editorHeightPx);

  const expanded = layout.results.expanded;

  useEffect(() => {
    setHeight(document.layout.editorHeightPx);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on document ID change
  }, [document.id]);

  const syncEditorHeight = () => {
    if (expanded) {
      adjustEditorHeight(height);
    } else {
      adjustEditorHeight();
    }
  };

  useEffect(() => {
    syncEditorHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only need to run when layout.results.expanded changes
  }, [expanded]);

  const script = getDocumentScript(document, scriptsState);

  if (script === null) {
    return null;
  }

  return (
    <>
      <Resizable
        width={Infinity}
        onResize={(event, { size }) => {
          if (height !== size.height) {
            setHeight(size.height);
          }
        }}
        onResizeStop={(event, { size }) => {
          adjustEditorHeight(size.height);

          changeDocumentLayout(document.id, {
            editorHeightPx: size.height,
          });
        }}
        axis="y"
        height={height}
        minConstraints={[Infinity, 150]}
        maxConstraints={[Infinity, 600]}
        handle={
          <div
            className={cn(styles.resizeHandler, {
              [styles.hidden]: !expanded,
            })}
          />
        }
        resizeHandles={["s"]}
      >
        <div
          className={cn(styles.root)}
          ref={rootRef}
          style={{
            height: expanded
              ? `${height}px`
              : `calc(100% - ${HEADER_HEIGHT_PX}px - ${FOOTER_HEIGHT_PX}px)`,
          }}
        >
          <div className={styles.body}>
            <DocumentEditor
              key={document.id}
              initialContent={script.content}
              initialSelection={document.selection}
              onChange={(content: string, selection: [number, number]) => {
                if (content === script.content) return;

                updateDocumentContent(document.id, content);
                updateDocumentSelection(document.id, selection);
              }}
              onCursorActivity={selection => {
                updateDocumentSelection(document.id, selection);
              }}
              onInit={cm => {
                setActiveEditorView(cm);

                syncEditorHeight();
              }}
              onRun={runText}
            />
          </div>
        </div>
      </Resizable>
    </>
  );
};

export default DocumentContent;
