import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { FIREBOLT_DOCS_URL } from "utils/constants";

import Button, { ButtonTemplate } from "components/Button";
import Checkbox from "components/Checkbox";
import ColumnFilters from "components/ColumnFilters";
import {
  InlineNotification,
  NotificationType,
} from "components/Notification/InlineNotification";
import { Step } from "components/Wizard/Step/Step";

import {
  IngestionStep,
  MapDataStep,
  MapExistingTableStep,
  NEW_COLUMN,
  WizardDataType,
} from "../types";
import Table from "./Table";
import { useVisibleColumns } from "./useVisibleColumns";
import { getPrimaryIndex, normalizeWizardData } from "./utils";

import styles from "./styles.module.scss";

const tableHeader = [
  { displayName: "wizard.map_data.name", name: "name", type: "" },
  { displayName: "wizard.map_data.type", name: "type", type: "" },
  { displayName: "wizard.map_data.nullable", name: "nullable", type: "" },
  {
    displayName: "wizard.map_data.primaryIndex",
    name: "primaryIndex",
    type: "",
  },
  {
    displayName: "wizard.map_data.data_preview",
    name: "dataPreview",
    type: "",
  },
];

type Props = {
  onClose: () => void;
  onSubmit: (data: MapDataStep | MapExistingTableStep) => void;
  wizardData: WizardDataType;
  onPrevStep: () => void;
  activeStepIndex: number;
  initialData: MapDataStep | MapExistingTableStep;
};

const PrimaryIndexWarning = (props: {
  primaryIndexes: {
    warning: {
      display: boolean;
      skip: boolean;
    };
  };
  setPrimaryIndexes: Dispatch<
    SetStateAction<{
      warning: { display: boolean; skip: boolean };
      autoPrimaryIndex: boolean;
      indexes: number[];
    }>
  >;
}) => {
  const { primaryIndexes, setPrimaryIndexes } = props;
  const { t } = useTranslation();
  if (!primaryIndexes.warning.display || primaryIndexes.warning.skip) {
    return null;
  }

  const hideWarning = () => {
    setPrimaryIndexes(currentPrimaryIndexes => ({
      ...currentPrimaryIndexes,
      warning: {
        display: true,
        skip: true,
      },
    }));
  };

  return (
    <div className={styles.warning}>
      <InlineNotification
        type={NotificationType.Error}
        title={t("wizard.map_data.primary_index_warning")}
        body={t("wizard.map_data.primary_index_warning_body")}
        onDismiss={hideWarning}
        primaryAction={{
          value: t("wizard.map_data.ignore"),
          type: ButtonTemplate.Tertiary,
          onAction: hideWarning,
        }}
        secondaryAction={{
          value: t("wizard.map_data.learn_more"),
          type: ButtonTemplate.Tertiary,
          href: `${FIREBOLT_DOCS_URL}/Guides/using-indexes.html`,
        }}
      />
    </div>
  );
};
export const MapNewTable = (props: Props) => {
  const {
    onClose,
    onSubmit,
    wizardData,
    initialData,
    activeStepIndex,
    onPrevStep,
  } = props;
  const { t } = useTranslation();
  const previewData = wizardData[IngestionStep.formatData]?.previewData!;
  const [data, setData] = useState<MapDataStep["data"]>(
    () => (initialData as MapDataStep)?.data || normalizeWizardData(previewData)
  );
  const { filterColumns, visibleColumns, columns } =
    useVisibleColumns(tableHeader);

  const [primaryIndexes, setPrimaryIndexes] = useState(() => {
    if ((initialData as MapDataStep)?.primaryIndexes) {
      return {
        autoPrimaryIndex: (initialData as MapDataStep).autoPrimaryIndex ?? true,
        indexes: (initialData as MapDataStep).primaryIndexes,
        warning: {
          display: false,
          skip: false,
        },
      };
    }
    const primaryIndex = getPrimaryIndex(data);
    const index = data.findIndex(row => row.name === primaryIndex.name);
    return {
      autoPrimaryIndex: true,
      indexes: [index],
      warning: {
        display: false,
        skip: false,
      },
    };
  });

  const selectedIndexes = primaryIndexes.indexes.filter(index => {
    const column = data[index];
    return column.included;
  });

  const applyDataChanges = (data: MapDataStep["data"]) => {
    const getAutoIndex = () => {
      const primaryIndex = getPrimaryIndex(data);
      if (primaryIndex) {
        const index = data.findIndex(row => row.name === primaryIndex.name);
        return [index];
      }
      return [];
    };

    setPrimaryIndexes(currentPrimaryIndexes => ({
      warning: {
        display: false,
        skip: false,
      },
      indexes: primaryIndexes.autoPrimaryIndex
        ? getAutoIndex()
        : currentPrimaryIndexes.indexes,
      autoPrimaryIndex: currentPrimaryIndexes.autoPrimaryIndex,
    }));

    setData(data);
  };

  const onChange = (rowIndex: number) => {
    applyDataChanges(
      data.map((row, index) => {
        if (rowIndex === index) {
          return {
            ...row,
            included: !row.included,
          };
        }

        return row;
      })
    );
  };

  const onChangeDestinationName = (editingRow: number, name: string) => {
    applyDataChanges(
      data.map((row, index) => {
        if (index === editingRow) {
          return {
            ...row,
            overrideName: name,
            error: null,
          };
        }
        return row;
      })
    );
  };

  const toggleNullable = (rowIndex: number) => {
    applyDataChanges(
      data.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            type: row.type.includes(" null")
              ? row.type.split(" null")[0]
              : `${row.type} null`,
          };
        }

        return row;
      })
    );
  };

  const togglePrimaryIndex = (rowIndex: number) => {
    setPrimaryIndexes(currentPrimaryIndexes => {
      const indexes = currentPrimaryIndexes.indexes.includes(rowIndex)
        ? currentPrimaryIndexes.indexes.filter(index => index !== rowIndex)
        : [...currentPrimaryIndexes.indexes, rowIndex];

      return {
        warning: {
          display: false,
          skip: false,
        },
        autoPrimaryIndex: false,
        indexes,
      };
    });
  };

  const onChangeType = (rowIndex: number, type: string) => {
    applyDataChanges(
      data.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            type: row.type.includes(" null") ? `${type} null` : type,
          };
        }

        return row;
      })
    );
  };

  const onChangeAll = (checked: boolean) => {
    applyDataChanges(
      data.map(d => {
        return {
          ...d,
          included: checked,
        };
      })
    );
  };

  const onFormSubmit = () => {
    const names = new Set();

    const validatedData = data.map(column => {
      if (column.included && names.has(column.overrideName || column.name)) {
        return {
          ...column,
          error: t("wizard.map_data.errors.already_exists"),
        };
      }
      names.add(column.overrideName || column.name);
      return column;
    });

    if (validatedData.some(column => column.error)) {
      applyDataChanges(validatedData);
      return;
    }

    if (!selectedIndexes.length && !primaryIndexes.warning.skip) {
      setPrimaryIndexes(currentPrimaryIndexes => ({
        ...currentPrimaryIndexes,
        warning: {
          display: true,
          skip: false,
        },
      }));
      return;
    }

    onSubmit({
      data,
      primaryIndexes: primaryIndexes.indexes,
      autoPrimaryIndex: primaryIndexes.autoPrimaryIndex,
    });
  };

  const handleAddColumn = () => {
    applyDataChanges([...data, NEW_COLUMN]);
  };

  const toggleAutoPrimaryIndex = () => {
    const getAutoIndex = () => {
      const primaryIndex = getPrimaryIndex(data);
      const index = data.findIndex(row => row.name === primaryIndex.name);
      return [index];
    };
    setPrimaryIndexes(currentPrimaryIndexes => {
      return {
        indexes: !currentPrimaryIndexes.autoPrimaryIndex
          ? getAutoIndex()
          : currentPrimaryIndexes.indexes,
        autoPrimaryIndex: !currentPrimaryIndexes.autoPrimaryIndex,
        warning: {
          display: false,
          skip: false,
        },
      };
    });
  };

  const body = (
    <div className={styles.mapData}>
      <div className={styles.autoPrimaryIndex}>
        <Checkbox
          onChange={toggleAutoPrimaryIndex}
          checked={primaryIndexes.autoPrimaryIndex}
          testId="map-data-auto-primary-index"
        />
        <span className={styles.autoPrimaryIndex__label}>
          {t("wizard.map_data.auto_index")}
        </span>
      </div>
      <PrimaryIndexWarning
        setPrimaryIndexes={setPrimaryIndexes}
        primaryIndexes={primaryIndexes}
      />
      <div className={styles.tableControls}>
        <div className={styles.tableTitle}>
          {t("wizard.map_data.table_title")}
        </div>
        <div className={styles.actions}>
          <div className={styles.addButton}>
            <Button
              dataTestid="add-column"
              onClick={handleAddColumn}
              text="Add column"
              template={ButtonTemplate.Secondary}
            />
          </div>
          <div className={styles.columnFilterIcon}>
            <ColumnFilters
              columns={tableHeader}
              visibleColumns={visibleColumns}
              handleColumnClick={filterColumns}
            />
          </div>
        </div>
      </div>
      <Table
        wizardData={wizardData}
        indexes={selectedIndexes}
        data={data}
        meta={columns}
        visibleColumns={visibleColumns}
        onChangeDestinationName={onChangeDestinationName}
        onChange={onChange}
        onChangeAll={onChangeAll}
        toggleNullable={toggleNullable}
        togglePrimaryIndex={togglePrimaryIndex}
        onChangeType={onChangeType}
      />
    </div>
  );

  return (
    <Step
      title={t("wizard.map_data.title")}
      subtitle={t("wizard.map_data.subtitle")}
      body={body}
      onClose={onClose}
      onSubmit={onFormSubmit}
      onPrevStep={onPrevStep}
      disabledSubmit={!data.some(d => d.included)}
      activeStepIndex={activeStepIndex}
    />
  );
};
