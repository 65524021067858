import { useCallback, useEffect, useState } from "react";

import { useLoggedInUser } from "hooks/useLoggedInUser";

import ContactSupport from "./ContactSupport";

type Options = {
  onOpen?: () => void;
};

export const useContactSupport = (options?: Options) => {
  const onOpen = options?.onOpen;
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const { isLoggedIn } = useLoggedInUser();

  useEffect(() => {
    if (!isLoggedIn) {
      setFormOpen(false);
    }
  }, [isLoggedIn]);

  const openForm = useCallback(() => {
    onOpen?.();
    setFormOpen(true);
  }, [onOpen]);

  const closeForm = useCallback(() => {
    setFormOpen(false);
  }, []);

  const contactSupportMarkup = formOpen ? (
    <ContactSupport onClose={closeForm} />
  ) : null;

  return {
    openForm,
    closeForm,
    contactSupportMarkup,
  };
};
