export enum ShortcutHandlerType {
  workspace = "workspace",
  editor = "editor", // handled in editor commands
  none = "none", // handled in the components
}

export enum Modifier {
  altKey = "altKey",
  ctrlKey = "ctrlKey",
  metaKey = "metaKey",
  shiftKey = "shiftKey",
}

export enum ShortcutAction {
  ScriptsClose = "ScriptsClose",
  ScriptsCloseAll = "ScriptsCloseAll",
  ScriptsCloseAllOther = "ScriptsCloseAllOther",
  ScriptOpenNew = "ScriptOpenNew",
  ShortcutsOverlayOpen = "ShortcutsOverlayOpen",
  ScriptFormat = "ScriptFormat",
  ScriptNext = "ScriptNext",
  ScriptPrevious = "ScriptPrevious",
  ToggleResultsSection = "ToggleResultsSection",
  EditorRunSelection = "EditorRunSelection",
  EditorRunAll = "EditorRunAll",
  CloseOverlay = "CloseOverlay",
  ScriptToggleComment = "ScriptToggleComment",
}

export interface Shortcut {
  action: ShortcutAction;
  title: string;
  handlerType: ShortcutHandlerType;
  mac: {
    hint: string[];
    command: {
      codes: string[];
      modifiers: [Modifier, ...Modifier[]];
    } | null;
  };
  windows: {
    hint: string[];
    command: {
      codes: string[];
      modifiers: [Modifier, ...Modifier[]];
    } | null;
  };
}

export interface ShortcutGroup {
  groupTitle: string;
  shortcuts: ShortcutAction[];
}
