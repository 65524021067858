import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useRbacRoles } from "services/rbac/useRbacRoles";

import { TextInput } from "components/TextInput/TextInput";
import { Step } from "components/Wizard/Step/Step";

import { NameStepType } from "../types";
import { Fields, schema } from "./schema";

import styles from "./styles.module.scss";

type Props = {
  initialData: NameStepType;
  onClose: () => void;
  onSubmit: (data: Fields) => void;
  onPrevStep: () => void;
  activeStepIndex: number;
};

export const NameStep = (props: Props) => {
  const { onClose, activeStepIndex, onPrevStep, initialData, onSubmit } = props;
  const { t } = useTranslation();
  const roles = useRbacRoles();

  const form = useForm<Fields>({
    resolver: zodResolver(schema),
    defaultValues: initialData,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = form;

  const body = (
    <div className={styles.rows}>
      <div className={styles.formControl}>
        <TextInput
          inputRootClassName={styles.inputRoot}
          label={t("roles_wizard.name.role_name")}
          testId="role-name-field"
          {...register("name")}
          error={!!errors?.name}
          helperText={errors?.name?.message}
          required
          autoFocus
          showAsterisk
        />
      </div>
    </div>
  );

  const onFormSubmit = (data: Fields) => {
    if (data.name && roles.find(role => role.roleName === data.name)) {
      setError("name", {
        message: t("roles_wizard.name.already_exists"),
      });
      return;
    }
    onSubmit(data);
  };

  return (
    <Step
      title={t("roles_wizard.name.title")}
      subtitle={t("roles_wizard.name.subtitle")}
      mainActionTitle={t("roles_wizard.privileges.title")}
      body={body}
      onClose={onClose}
      onSubmit={handleSubmit(onFormSubmit)}
      activeStepIndex={activeStepIndex}
      onPrevStep={onPrevStep}
      titleTestId="roles-wizard-name-title"
    />
  );
};
