import * as Sentry from "@sentry/browser";
import React from "react";
import { fetchVersion } from "utils/maintenance";

import Loader from "components/EllipsisLoader";

import AppCrash from "./AppCrash";
import NewAppVersion from "./NewAppVersion";
import { NotAMember, NotAMemberError } from "./NotAMember";
import { Unauthorized } from "./Unauthorized";
import { UnauthorizedError } from "./UnauthorizedError";

interface IState {
  hasError: boolean;
  chunkError: boolean;
  isNewVersion: boolean;
  notAMember?: boolean;
  unauthorizedError?: boolean;
}

type Props = {
  children?: React.ReactNode;
  layout?: (errorElement: React.ReactElement) => React.ReactElement;
  resetError?: () => void;
  onCaught?: () => void;
};

class ErrorBoundary extends React.Component<Props, IState> {
  public static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      chunkError: error.name === `ChunkLoadError`,
      isNewVersion: false,
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      chunkError: false,
      isNewVersion: false,
    };
  }

  async componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (error instanceof NotAMemberError) {
      return this.setState({ notAMember: true });
    }
    if (error instanceof UnauthorizedError) {
      this.captureError(error, errorInfo);
      return this.setState({ unauthorizedError: true });
    }
    if (this.state.chunkError) {
      const latestVersion = await fetchVersion();
      const currentMetaVersion = (
        document.getElementById("app-version") as HTMLMetaElement
      ).content;

      if (latestVersion !== currentMetaVersion) {
        this.setState(() => ({
          hasError: false,
          chunkError: false,
          isNewVersion: true,
        }));
      } else {
        this.captureError(error, errorInfo);
      }
    } else {
      this.captureError(error, errorInfo);
    }

    if (this.props.onCaught) {
      this.props.onCaught();
    }
  }

  captureError(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras({
        ...errorInfo,
      });
      Sentry.captureException(error);
    });
    this.setState(() => ({
      hasError: true,
      chunkError: false,
      isNewVersion: false,
    }));
  }

  public render() {
    let errorElement = null;

    if (this.state.notAMember) {
      errorElement = <NotAMember resetError={this.props.resetError} />;
    } else if (this.state.unauthorizedError) {
      errorElement = <Unauthorized />;
    } else if (this.state.chunkError) {
      errorElement = <Loader />;
    } else if (this.state.isNewVersion) {
      errorElement = <NewAppVersion />;
    } else if (this.state.hasError) {
      errorElement = <AppCrash />;
    }

    if (errorElement) {
      return this.props.layout ? this.props.layout(errorElement) : errorElement;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
