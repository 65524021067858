import { isMac } from "services/browser";

import {
  Modifier,
  Shortcut,
  ShortcutHandlerType,
} from "pages/DevelopWorkspace/services/shortcuts/types";

export const shouldTriggerShortcut = (
  shortcut: Shortcut,
  pressedKeys: string[],
  modifiers: [Modifier, ...Modifier[]]
) => {
  if (shortcut.handlerType !== ShortcutHandlerType.workspace) {
    return false;
  }

  let command;

  if (isMac && shortcut.mac.command) {
    command = shortcut.mac.command;
  } else if (!isMac && shortcut.windows.command) {
    command = shortcut.windows.command;
  }

  if (!command) {
    return false;
  }

  if (command.modifiers.every(modifier => modifiers.includes(modifier))) {
    // all modifiers are pressed

    if (command.codes.every(code => pressedKeys.includes(code))) {
      // all keys are pressed
      return true;
    }
  }

  return false;
};
