import classNames from "classnames";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button, { ButtonSize, ButtonTemplate } from "components/Button";
import styles from "components/DefinitionCodePreview/DefinitionCodePreview.module.scss";
import ReadOnlyEditor from "components/ReadOnlyEditor";

interface DefinitionCodePreviewProps {
  code: string;
  onSecondaryButtonClick?: () => void;
  showFooter?: boolean;
  className?: string;
}

const DefinitionCodePreview = (props: DefinitionCodePreviewProps) => {
  const { code, onSecondaryButtonClick, showFooter = true, className } = props;
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <div className={classNames(styles.definitionCodePreview, className)}>
      <div
        className={styles.editorWrapper}
        data-testid="definition-code-preview"
      >
        <ReadOnlyEditor value={code} />
      </div>
      {showFooter && (
        <>
          <div className={styles.divider} />
          <div className={styles.footer}>
            <Button
              template={ButtonTemplate.Secondary}
              size={ButtonSize.Small}
              dataTestid="copy-definition-button"
              text={
                copied
                  ? t("view_definitions.copied")
                  : t("view_definitions.copy")
              }
              onClick={() => {
                copy(code);
                setCopied(true);
              }}
            />

            {onSecondaryButtonClick && (
              <Button
                template={ButtonTemplate.Tertiary}
                size={ButtonSize.Small}
                dataTestid="close-definition-button"
                text={t("view_definitions.insert_to_script")}
                onClick={onSecondaryButtonClick}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DefinitionCodePreview;
