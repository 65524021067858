const CANCEL_MESSAGE = "Query was canceled";

const isCancelError = (json: { errors?: { description?: string }[] }) => {
  if (json.errors?.[0]?.description === CANCEL_MESSAGE) {
    return true;
  }

  return false;
};

export const isCancelMessage = (message: string): boolean => {
  return message === CANCEL_MESSAGE;
};

export default isCancelError;
