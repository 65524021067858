import React from "react";

import { DocumentStatus } from "pages/DevelopWorkspace/Editor/Document/Document";
import RunButton from "pages/DevelopWorkspace/Editor/Document/DocumentContent/RunButton/RunButton";
import FlagsMenu from "pages/DevelopWorkspace/Editor/Document/FlagsMenu";
import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import {
  CancellationStatus,
  ExecutionType,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import styles from "./DocumentActionsPanel.module.scss";

interface DocumentActionsPanelProps {
  document: WorkspaceDocument;
  documentStatus: DocumentStatus;
  executionType: ExecutionType;
  onExecutionTypeChange: (type: ExecutionType) => void;
}

const DocumentActionsPanel = (props: DocumentActionsPanelProps) => {
  const { document, documentStatus, executionType, onExecutionTypeChange } =
    props;

  const {
    actions: {
      startDocumentExecution,
      changeDocumentContext,
      cancelDocumentExecution,
    },
  } = useDocuments();

  const { activeEditorView } = useActiveEditorView();

  const handleChangeExecutionType = (type: ExecutionType) => {
    onExecutionTypeChange(type);
  };

  const cancelDocument = () => {
    if (
      document.execution?.cancellationStatus === CancellationStatus.Initiated ||
      document.execution?.cancellationStatus === CancellationStatus.Pending
    ) {
      return;
    }

    cancelDocumentExecution(document.id);
  };

  const getTextToRun = () => {
    if (!activeEditorView) {
      return "";
    }

    const docText = activeEditorView.state.doc.toString();

    if (docText === "") {
      return "";
    }

    const range = activeEditorView.state.selection.ranges[0];

    if (range.from === range.to) {
      // no selection, run the whole document
      return docText;
    }

    return activeEditorView.state.doc.sliceString(range.from, range.to);
  };

  const runDocument = () => {
    if (documentStatus.isRunning) {
      cancelDocument();
    }

    if (!activeEditorView) {
      return;
    }

    const textToRun = getTextToRun();
    startDocumentExecution(document.id, textToRun, executionType);
  };

  return (
    <div className={styles.documentActionsPanel}>
      {Object.keys(document.context.settings).length > 0 && (
        <div className={styles.control}>
          <FlagsMenu
            settings={document.context.settings}
            isDocumentRunning={documentStatus.isRunning}
            onRemoveAll={() => {
              changeDocumentContext(document.id, {
                settings: {},
              });
            }}
            onRemove={flag => {
              const newSettings = { ...document.context.settings };
              delete newSettings[flag];

              changeDocumentContext(document.id, {
                settings: {
                  ...newSettings,
                },
              });
            }}
          />
        </div>
      )}

      <RunButton
        executionType={executionType}
        handleChangeExecutionType={handleChangeExecutionType}
        isRunning={documentStatus.isRunning}
        isUnknown={documentStatus.isUnknown}
        isRestoring={documentStatus.isRestoring}
        isCanceling={documentStatus.isCanceling}
        onClick={() => {
          if (documentStatus.isUnknown || documentStatus.isCanceling) {
            return;
          }
          if (documentStatus.isRunning) {
            cancelDocument();
            return;
          }

          runDocument();
        }}
      />
    </div>
  );
};

export default DocumentActionsPanel;
