import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { FIREBOLT_DOC_SHORTCUTS_URL } from "utils/constants";

import { isMac } from "services/browser";

import CloseIcon from "assets/icons/Close.svg?react";

import { useShortcutsOverlay } from "pages/DevelopWorkspace/contexts/ShortcutsOverlayContext/ShortcutsOverlayContext";
import {
  SHORTCUTS,
  SHORTCUT_GROUPS,
} from "pages/DevelopWorkspace/services/shortcuts/constants";
import { ShortcutGroup } from "pages/DevelopWorkspace/services/shortcuts/types";

import styles from "./ShortcutsOverlay.module.scss";

const ShortcutsOverlay = () => {
  const { open, closeOverlay } = useShortcutsOverlay();
  const { t } = useTranslation();

  const [os, setOs] = React.useState<"mac" | "windows">(
    isMac ? "mac" : "windows"
  );

  // handle escape key press
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        event.stopPropagation();
        closeOverlay();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [closeOverlay]);

  if (!open) return null;

  const renderGroup = (group: ShortcutGroup, primary: boolean = false) => {
    return (
      <div
        key={group.groupTitle}
        className={styles.group}
      >
        <h2 className={styles.groupTitle}>{t(group.groupTitle)}</h2>
        <ul className={styles.shortcuts}>
          {group.shortcuts.map(shortcutAction => {
            const shortcut = SHORTCUTS[shortcutAction];

            const hint =
              os === "mac" ? shortcut.mac.hint : shortcut.windows.hint;

            return (
              <li
                key={shortcut.action}
                className={styles.shortcut}
              >
                <div className={styles.title}>{t(shortcut.title)}</div>
                <div className={styles.buttons}>
                  {hint.map(key => {
                    return (
                      <div
                        className={cn(styles.button, {
                          [styles.primary]: primary,
                          [styles.short]: key.length === 1,
                          [styles.long]: key.length > 1,
                        })}
                        key={key}
                      >
                        {key}
                      </div>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className={styles.shortcutsOverlay}>
      <div
        className={styles.closeIcon}
        onClick={closeOverlay}
      >
        <CloseIcon />
      </div>

      <div className={styles.body}>
        <h1 className={styles.header}>{t("shortcuts_overlay.header")}</h1>

        <div className={styles.osSelector}>
          <div
            className={cn(styles.osButton, {
              [styles.active]: os === "windows",
            })}
            onClick={() => setOs("windows")}
          >
            {t("shortcuts_overlay.windows_and_linux")}
          </div>
          <div
            className={cn(styles.osButton, {
              [styles.active]: os === "mac",
            })}
            onClick={() => setOs("mac")}
          >
            {t("shortcuts_overlay.mac")}
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.column}>
            {renderGroup(SHORTCUT_GROUPS.action, true)}
            {renderGroup(SHORTCUT_GROUPS.editing)}
          </div>

          <div className={styles.column}>
            {renderGroup(SHORTCUT_GROUPS.workspace)}
          </div>
        </div>

        <div className={styles.linkContainer}>
          <a
            href={FIREBOLT_DOC_SHORTCUTS_URL}
            target="_blank"
            className={styles.link}
            rel="noreferrer"
          >
            {t("shortcuts_overlay.link")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ShortcutsOverlay;
